/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { getImage } from '../../../utils';
import { Container, Image, Title, Subtitle } from './index.styled';

const FeatureSolucoesComponent = ({
  className,
  style,
  data: { title, description, image },
}) => {
  return (
    <Container className={`glide__slide ${className}`} style={style}>
      <Image image={getImage(image)} />
      <div className="texts-container">
        <div style={{ marginLeft: '6px' }}>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <Subtitle dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </Container>
  );
};

FeatureSolucoesComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf([null])]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf([null])]),
};

FeatureSolucoesComponent.defaultProps = {
  className: '',
  style: {},
  data: {},
};

export default FeatureSolucoesComponent;
