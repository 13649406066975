import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-purple: #c135e0;
    --color-purple-dark: #86259b;
    --color-purple-dark-1: #3c0548;
    --color-purple-dark-2: #85259b38;
    --color-white: #ffffff;
    --color-pink: #cc0163;
    --color-pink-description: #d42b7d;
    --color-yellow: #f5eabd;
    --color-yellow-2: #ffc800;
    --background-gradient: linear-gradient(245deg, #9b26b5, #86259b);
    --background-gradient-footer: linear-gradient(239deg, #86259b 20%, #cc0163);
    --scroll: smooth;
  }
background-image: linear-gradient(245deg, #9b26b5 99%, #86259b 2%);
  @font-face {
      font-family: "titulos";
      src: url("../fonts/Montserrat-Bold.ttf") format("truetype")
  }

  @font-face {
      font-family: "subtitulos";
      src: url("../fonts/Montserrat-Medium.ttf") format("truetype")
  }

  @font-face {
      font-family: "padrao";
      src: url("../fonts/Poppins-Light.otf") format("opentype")
  }

  @font-face {
      font-family: "padrao-bold";
      src: url("../fonts/Poppins-BoldItalic.otf") format("opentype")
  }

  @font-face {
      font-family: "titulos-bold";
      src: url("../fonts/Poppins-Bold.otf") format("opentype")
  }

  html,
  body {
      width: 100%!important;
      padding: 0!important;
      margin: 0!important;
      font-family: 'padrao'!important;
      font-size: 16px;
  }

  h2 {
    font-family: "subtitulos";
    font-size: .74rem;
    &.subtitle {
      margin: 0;
      margin-bottom: 12px;
      letter-spacing: 1.5px;
      text-transform: uppercase;

      @media (min-width: 768px) {
        margin-bottom: 20px;
      }

      &.pink {
        color: var(--color-pink);
      }

      &.yellow {
        color: var(--color-yellow-2);
      }
    }
  }

  ul, li, a {
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  h1,
  h3,
  h4,
  h5,
  h6 {
      font-family: 'titulos';
      font-weight: bold;
  }

  * {
    box-sizing: border-box;
    scroll-behavior: var(--scroll);
    -webkit-font-smoothing: antialiased;
  }

  ::-webkit-scrollbar {
      width: 10px;
  }

  ::-webkit-scrollbar-track {
      background: #FFF;
  }

  ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: var(--color-roxo);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .fluid {
    width: 100%;
    padding: 0 20px;

    &.bg-white {
      background: var(--color-white);
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1340px;
    height: 100%;
  }

  .destaque {
    font-family: "titulos";
    font-size: 1.8rem;
    letter-spacing: .07rem;
    line-height: 1.8rem;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 3.6rem;
      letter-spacing: .14rem;
      line-height: 3.8rem;
    }

    &.smaller {
      font-size: 1.6rem;
      @media (min-height: 768px) {
        font-size: 2.7rem;
        letter-spacing: .03rem;
        line-height: 3.4rem;
        font-weight: bold;
      }
    }

    &.title-purple {
      color: var(--color-purple-dark);
    }

    &.title-white {
      color: var(--color-white);
    }

    &.yellow-line {
      display: inline;
      background-image: linear-gradient(to right,#f5e9be 0%,#f5e9be 100%);
      background-size: 100% 20%;
      background-repeat: no-repeat;
      background-position: 0% 76%;
      margin: 0;
    }

    &.white-line {
      display: inline;
      background-image: linear-gradient(to right, rgba(255,255,255, .3) 0%,rgba(255,255,255, .3) 100%);
      background-size: 100% 20%;
      background-repeat: no-repeat;
      background-position: 0% 76%;
      margin: 0;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1340px;
  height: 100%;
`;
