import React from 'react';
import { useResize } from '../../hooks';
import { Description, ButtonRounded, SeloMicrosoftPartner } from '..';
import { ContainerFluid, Container, WrapperContent } from './index.styled';

const QuemSomosComponent = () => {
  const { isMobile } = useResize();
  return (
    <ContainerFluid id="quem-somos" className="fluid">
      <Container>
        <WrapperContent>
          <h2 className="subtitle pink">Quem somos</h2>
          <div className="wrapper-title">
            <p className="destaque title-purple yellow-line">
              Desenvolvemos muito mais do que código.
            </p>
          </div>
          <Description
            style={{ marginTop: isMobile ? '10px' : '42px' }}
            color="#000000"
          >
            Somos especialistas em criar{' '}
            <em>diferencial competitivo através da tecnologia</em>. <br />
            Geramos resultados porque temos um time de talentos apaixonado e
            realmente comprometido.
          </Description>
          <SeloMicrosoftPartner
            style={{ marginTop: isMobile ? '40px' : '66px' }}
          />
          <ButtonRounded
            style={{ marginTop: isMobile ? '50px' : '120px', display: 'none' }}
          >
            Conheça a Engenhos
          </ButtonRounded>
        </WrapperContent>
      </Container>
    </ContainerFluid>
  );
};

export default QuemSomosComponent;
