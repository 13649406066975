import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './index.styled';

const ButtonRoundedComponent = ({ color, style, children, onPress }) => {
  return (
    <Button style={style} color={color} onClick={onPress}>
      {children}
    </Button>
  );
};

ButtonRoundedComponent.propTypes = {
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf([null])]),
  onPress: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ButtonRoundedComponent.defaultProps = {
  color: 'var(--color-purple-dark)',
  style: {},
  onPress: () => {},
};

export default ButtonRoundedComponent;
