import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 0 70px 0;
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 768px) {
    position: relative;
    min-height: 490px;
    padding: 50px 0 0px 0;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
  }
`;

export const ContainerFluid = styled.div`
  position: relative;
  min-height: 300px;
  /* max-height: 120vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
  padding-top: 60px;
  background-image: var(--background-gradient);
  @media (min-width: 768px) {
    min-height: 100vh;
    padding-top: 0px;
  }
`;

export const WrapperContent = styled.div`
  @media (min-width: 768px) {
    max-width: 100%;

    .wrapper-title {
      max-width: 100%;
    }
  }
`;
