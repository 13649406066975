import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Background = styled(motion.div)`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.61);
  z-index: 10;
`;

export const Container = styled(motion.nav)`
  width: calc(100% - 48px);
  height: 100%;
  background: var(--color-purple-dark);
  padding: 24px 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const Nav = styled.nav`
  display: flex;
  padding-top: 30px;
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Button = styled.li`
  text-transform: uppercase;
  margin: 10px 0;
  letter-spacing: 4px;
  font-size: 0.8rem;
  opacity: 1;
  transition: all 0.3s ease;

  &:hover,
  &:active {
    opacity: 0.9;
    a {
      color: var(--color-purple);
    }
  }
  a {
    display: block;
    padding: 4px 8px;
    color: var(--color-white);
  }
`;
