import styled from 'styled-components';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
  height: 172px;
  margin-top: auto;
  background: var(--background-gradient-footer);

  @media (min-width: 768px) {
    height: 160px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 1340px;
  height: 100%;
  font-size: 0.63rem;
  letter-spacing: 0.5px;

  @media (max-width: 767px) {
    padding: 38px 0 0 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-size: 0.85rem;
    letter-spacing: 0.4px;
  }

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const DivContactInfos = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  @media (max-width: 767px) {
    width: 100%;
    border-top: solid 1px rgba(255, 255, 255, 0.35);
    margin-top: 20px;
    padding-top: 14px;

    div:not(:first-child) {
      padding-right: 18px;
    }

    div:first-child {
      order: 1;
      padding-left: 18px;
    }
  }

  @media (min-width: 768px) {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
`;

export const DivParagraph = styled.div`
  position: relative;
  color: var(--color-white);

  &.separator {
    &:after {
      content: '|';
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;

      @media (min-width: 768px) {
        display: none;
      }

      @media (min-width: 1100px) {
        display: block;
      }
    }
  }

  @media (min-width: 768px) {
    padding-right: 50px;
  }

  a {
    color: var(--color-white);
    opacity: 1;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Termos = styled.div`
  color: var(--color-white);

  a {
    color: var(--color-white);
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 767px) {
    margin-top: 8px;
  }

  @media (min-width: 768px) {
    padding-left: 50px;
    position: absolute;
    top: 100px;
    right: 66px;
  }

  @media (min-width: 1100px) {
    position: relative;
    top: initial;
    right: initial;
  }
`;
