import React from 'react';
import PropTypes from 'prop-types';
import { useResize } from '../../hooks';
import SeloMicrosoftImage from '../../assets/images/selo_microsoft_partner_silver.png';
import SeloMicrosoftImageMobile from '../../assets/images/selo_microsoft_partner_silver_mobile.png';
import { Container } from './index.styled';

const SeloMicrosoftPartnerComponent = ({ style }) => {
  const { isMobile } = useResize();

  const getImageSize = () => {
    return isMobile ? SeloMicrosoftImageMobile : SeloMicrosoftImage;
  };
  return (
    <Container style={style}>
      <img
        src={getImageSize()}
        alt="Microsoft Partner | Silver DevOps | Silver Application Development"
      />
    </Container>
  );
};

SeloMicrosoftPartnerComponent.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf([null])]),
};

SeloMicrosoftPartnerComponent.defaultProps = {
  style: {},
};

export default SeloMicrosoftPartnerComponent;
