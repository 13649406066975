import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Home,
        PoliticaPrivacidade,
        TermosCondicoesDeUso,
        Vaga55Arquiteto,
        Vaga56AnalistaSr,
        Vaga57DevPl,
        Vaga58Techlead,
        Vagas } from './pages';
import { Header } from './components';
import { ContainerFluid } from './App.styled';

export default function Routes() {
  return (
    <ContainerFluid>
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          path="/politica-de-privacidade"
          exact
          component={PoliticaPrivacidade}
        />
        <Route
          path="/termos-e-condicoes-gerais-de-uso"
          exact
          component={TermosCondicoesDeUso}
        />
        <Route
          path="/vagas"
          exact
          component={Vagas}
        />
        <Route
          path="/vagas/55-arquiteto"
          exact
          component={Vaga55Arquiteto}
        />
        <Route
          path="/vagas/56-analista-sr"
          exact
          component={Vaga56AnalistaSr}
        />
        <Route
          path="/vagas/57-dev-pl"
          exact
          component={Vaga57DevPl}
        />
        <Route
          path="/vagas/58-techlead"
          exact
          component={Vaga58Techlead}
        />
      </Switch>
    </ContainerFluid>
  );
}
