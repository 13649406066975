import styled from 'styled-components';

import { ImgTrabalheConosco } from '../../assets';

export const ContainerFluidMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

export const ContainerFluid = styled.div`
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
  padding-top: 60px;
  @media (min-width: 768px) {
    padding-top: 160px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 0;
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  li {
    margin-bottom: 1rem;
    font-size: 0.87rem;
  }

  @media (min-width: 768px) {
    position: relative;
    min-height: 490px;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
  }
`;

export const WrapperContent = styled.div`
position: relative;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 100%;

    .wrapper-title {
      max-width: 100%;
    }
  }
`;

export const ImageDestaque = styled.div`
  position: absolute;
  top: 140px;
  left: 787px;
  width: 404px;
  height: 399px;
  background: url(${ImgTrabalheConosco});
  background-repeat: no-repeat;
  background-size: 404px 399px;
  pointer-events: none;
  border-radius: 8px;
  transform-origin: center;
  transform: scale(1);
  transition: all 0.3s ease;
  z-index: 1;
`;

export const ActiveLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 100%;

    .wrapper-title {
      max-width: 100%;
    }
  }

  a {
    color: var(--color-purple-dark);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-weight: bold;

    &:hover {
      color: var(--color-purple-dark-2);
    }
  }
`
