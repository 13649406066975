import styled from 'styled-components';
import { GrTrabalheConosco, ImgTrabalheConosco } from '../../assets';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 0;
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    position: relative;
    min-height: 490px;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      width: 2636px;
      height: 811px;
      top: 0;
      left: 720px;
      background: url(${GrTrabalheConosco});
      background-repeat: no-repeat;
      background-size: 2636px 811px;
      background-position: 0 0px;
      transform: scale(0.9);
      z-index: 0;
      pointer-events: none;
    }
  }
`;

export const ContainerFluid = styled.div`
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
  padding-top: 60px;
  @media (min-width: 768px) {
    min-height: 100vh;
    padding-top: 0px;
  }
`;

export const WrapperContent = styled.div`
  position: relative;
  @media (min-width: 768px) {
    max-width: 800px;
  }
`;

export const ImageDestaque = styled.div`
  position: absolute;
  top: 140px;
  left: 787px;
  width: 404px;
  height: 399px;
  background: url(${ImgTrabalheConosco});
  background-repeat: no-repeat;
  background-size: 404px 399px;
  pointer-events: none;
  border-radius: 8px;
  transform-origin: center;
  transform: scale(1);
  transition: all 0.3s ease;
  z-index: 1;
`;
