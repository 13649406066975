import React from 'react';
import { Footer, Description } from '../../components';
import { useResize } from '../../hooks';
import { ContainerFluidMain, ContainerFluid, Container, WrapperContent } from './index.styled';

const Vaga56AnalistaSrPage = () => {
  const { isMobile } = useResize();
  const textMarginDesktop = '20px';

  return (
    <ContainerFluidMain>
        <ContainerFluid id="vaga" className="fluid">
          <Container>
            <WrapperContent>
              <div className="wrapper-title">
                <p className="destaque title-purple smaller">
                  Oportunidade: Analista de Sistemas Sênior (Multimercado)
                </p>
              </div>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Aqui na Engenhos nos empenhamos em construir um ambiente no qual possamos ser felizes trabalhando com o que gostamos, tecnologia, e desenvolver times com diversidade é o primeiro passo para este objetivo.
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Estamos buscando pessoas de São Paulo para atuar como Analista de Sistemas em modelo home office full-time, com eventuais visitas aos escritórios dos clientes.
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Buscamos pessoas que não tenham medo de desafios e desejam conciliar o desenvolvimento profissional e pessoal, sempre antenados em novas tecnologias, dispostos a aprender e trazer as novidades tecnológicas e boas práticas da área para compartilhar com o time.
              </Description>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                COMPETÊNCIAS TÉCNICAS
              </Description>
              <ul>
                <li>
                Experiência como Product Owner ou Analista de Sistema
                </li>
                <li>
                Experiência com gestão de requisitos e trabalho através Azure DevOps e/ou Jira 
                </li>
                <li>
                Elaboração de wireframes e protótipos 
                </li>
                <li>
                Conhecimentos em acesso a banco de dados relacional e não-relacional 
                </li>
                <li>
                Atuação em multi-mercado 
                </li>
                <li>
                Conhecimento em MS-Project e Excel 
                </li>
              </ul>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                ATIVIDADES
              </Description>
              <ul>
                <li>
                Levantamento e detalhamento de requisitos junto aos clientes, mapeamento de escopo, elaboração de documentação funcional
                </li>
                <li>
                Dominar as capacidades funcionais dos sistemas e os processos de negócio relacionados 
                </li>
                <li>
                Entendimento da estrutura técnica dos sistemas (estruturas de dados, caches, divisões de responsabilidade, etc) e como estas dimensões (funcional e técnica) se relacionam
                </li>
                <li>
                Atuar ativamente para desenvolver o backlog dos sistemas
                </li>
                <li>
                Gestão de backlog 
                </li>
                <li>
                Trabalhar com a metodologia Ágil 
                </li>
                <li>
                Definição e documentação de processos 
                </li>
                <li>
                Acompanhamento de homologação com usuários 
                </li>
                <li>
                Elaboração de casos de teste 
                </li>
                <li>
                Execução de testes caixas preta e geração de evidências 
                </li>
                <li>
                Execução de testes exploratórios 
                </li>
              </ul>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                PARA SE CANDIDATAR
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Envie seu currículo para <a href="mailto:eliane.pimentel@engenhos.net">eliane.pimentel@engenhos.net</a>
              </Description>
            </WrapperContent>
          </Container>
        </ContainerFluid>
      <Footer />
    </ContainerFluidMain>
  );
};

export default Vaga56AnalistaSrPage;
