import styled from 'styled-components';
import { BackgroundPurpose } from './assets';

export const ContainerFluid = styled.div`
  width: 100%;
  background: var(--color-white);

  &.bg-header {
    background-image: url(${BackgroundPurpose});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 724px;
  }
`;

export const Container = styled.div`
  max-width: 1340px;
  margin: 0 auto;
`;
