import React from 'react';
import { Termos, Footer } from '../../components';
import { ContainerFluid } from './index.styled';

const TermosCondicoesDeUsoPage = () => {
  return (
    <ContainerFluid>
      <Termos />
      <Footer />
    </ContainerFluid>
  );
};

export default TermosCondicoesDeUsoPage;
