import React from 'react';
import { Footer, Description } from '../../components';
import { useResize } from '../../hooks';
import { ContainerFluidMain, ContainerFluid, Container, WrapperContent } from './index.styled';

const Vaga55ArquitetoPage = () => {
  const { isMobile } = useResize();
  const textMarginDesktop = '20px';

  return (
    <ContainerFluidMain>
        <ContainerFluid id="vaga" className="fluid">
          <Container>
            <WrapperContent>
              <div className="wrapper-title">
                <p className="destaque title-purple smaller">
                  Oportunidade: Arquiteto(a) de Software
                </p>
              </div>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Aqui na Engenhos nos empenhamos em construir um ambiente no qual possamos ser felizes trabalhando com o que gostamos, tecnologia, e desenvolver times com diversidade é o primeiro passo para este objetivo.
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Estamos buscando pessoas de qualquer parte do Brasil para atuar como Arquiteto(a) de Software, Home Office Full-time, em soluções centradas na plataforma .NET e predominantemente cloud. Estamos procurando por pessoas propositivas, assertivas e que gostam de um bom desafio. 
              </Description>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                RESPONSABILIDADES
              </Description>
              <ul>
                <li>
                  Tomar decisões técnicas - você deverá ser a última fronteira no que diz respeito a solução - claro que para tomar boas decisões será preciso minerar informações das pessoas e do ambiente, montar cenários, ponderar ganhos-e-perdas, provas de conceito e outras práticas de apoio a decisão
                </li>
                <li>
                  Realizar/coordenar benchmarks e provas-de-conceito para seleção de soluções de software e infra 
                </li>
                <li>
                  Levantar requisitos não-funcionais junto ao negócio e zelar pela aderência do sistema a estes requisitos 
                </li>
                <li>
                  Projetar e manter a arquitetura do software ao longo do ciclo de vida do sistema 
                </li>
                <li>
                  Ser hands-on e envolver-se na codificação e investigação de problemas 
                </li>
                <li>
                  Colaborar com as equipes de desenvolvimento na construção do sistema de forma alinhada aos requisitos e arquitetura 
                </li>
                <li>
                  Conduzir com o time de desenvolvimento a refatoração de sistema existente para adequação aos requisitos e arquitetura alvo
                </li>
              </ul>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                REQUISITOS TÉCNICOS
              </Description>
              <ul>
                <li>
                  UML (desejável C4) 
                </li>
                <li>
                  Domínio da linguagem C# (.NET) e competência com JavaScript/TypeScript 
                </li>
                <li>
                  Experiência com serviços de cloud computing (desejável Azure e/ou AWS) 
                </li>
                <li>
                  Sólidos conhecimentos de containers (Kubernetes, Docker, Compose) 
                </li>
                <li>
                  Experiência com Git, Azure DevOps, CI/CD 
                </li>
                <li>
                  Execução de code reviews 
                </li>
                <li>
                  Conhecimentos em qualidade de software (BDD, TDD, testes unitários, testes integrados, teste de carga, etc) 
                </li>
                <li>
                  Conhecimentos de Infrastructure as Code (ex: Terraform) 
                </li>
              </ul>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                PARA SE CANDIDATAR
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Envie seu currículo para <a href="mailto:eliane.pimentel@engenhos.net">eliane.pimentel@engenhos.net</a>
              </Description>
            </WrapperContent>
          </Container>
        </ContainerFluid>
      <Footer />
    </ContainerFluidMain>
  );
};

export default Vaga55ArquitetoPage;
