import React from 'react';
import { useResize } from '../../hooks';
import {
  ContainerFluid,
  Container,
  Section,
  Title,
  Paragraph,
} from './index.styled';

const PurposeDrivenComponent = () => {
  const { isMobile } = useResize();

  const variants = {
    initial: {
      x: -80,
      opacity: 0,
    },
    onEnter: {
      x: 0,
      opacity: 1,
    },
  };
  return (
    <ContainerFluid className="bg-header">
      <Container>
        <Section>
          <Title
            variants={variants}
            initial="initial"
            animate="onEnter"
            transition={{ duration: 0.8, ease: 'easeInOut', delay: '.5' }}
          >
            PURPOSE-DRIVEN
          </Title>
          <Paragraph
            variants={variants}
            initial="initial"
            animate="onEnter"
            transition={{ duration: 0.5, ease: 'easeOut', delay: '.9' }}
          >
            Um parceiro de tecnologia que se preocupa de verdade com as
            necessidades, propósitos e resultados do seu negócio.
          </Paragraph>
          <Paragraph
            variants={variants}
            initial="initial"
            animate="onEnter"
            transition={{ duration: 0.7, ease: 'easeOut', delay: '1.1' }}
          >
            <span>Prazer, somos a Engenhos.</span> {isMobile && <br />} Nós
            desenvolvemos soluções efetivas para os desafios atuais e futuros da
            sua empresa.
          </Paragraph>
        </Section>
      </Container>
    </ContainerFluid>
  );
};

export default PurposeDrivenComponent;
