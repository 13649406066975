import styled from 'styled-components';
import { LogoWhite, LogoColor } from '../../assets';

export const Container = styled.div`
  width: 239px;
  height: 56px;
  padding: 0 20px;
  background: url(${p => (p.type === 'white' ? LogoWhite : LogoColor)});
  background-repeat: no-repeat;
  background-size: contain;

  &.pointer {
    cursor: pointer;
  }

  &.bigger {
    width: 130px;
    height: 40px;

    @media (min-width: 768px) {
      width: 230px;
      height: 58px;
    }
  }
`;
