import styled from 'styled-components';
import { GrContato } from '../../assets';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 0;
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    position: relative;
    min-height: 490px;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      width: 1920px;
      height: 800px;
      top: 0;
      left: 1320px;
      background: url(${GrContato});
      background-repeat: no-repeat;
      background-size: 1920px 1027px;
      background-position: 0 -30px;
      transform: scale(1.3);
      z-index: 0;
      pointer-events: none;
    }
  }
`;

export const ContainerFluid = styled.div`
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-top: 60px;
  z-index: 1;
  @media (min-width: 768px) {
    min-height: 100vh;
    padding-top: 0px;
  }
`;

export const WrapperContent = styled.div`
  position: relative;
  @media (min-width: 768px) {
    max-width: 840px;
  }
`;

export const WrapperContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin-top: 92px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const WrapperInfo = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: calc(50% - 10px);
    & + div {
      padding-left: 20px;
    }
  }
  h3 {
    color: var(--color-black);
    margin: 0;
    font-size: 0.7rem;
    font-weight: bold;
    letter-spacing: 0.03rem;
    @media (min-width: 768px) {
      color: var(--color-black);
      margin: 0;
      font-size: 0.9rem;
      line-height: 0;
      letter-spacing: 0.09rem;
    }
  }
  a {
    color: var(--color-black);
    opacity: 1;
    transition: all 0.3s ease;

    &.email {
      text-decoration: underline;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;
