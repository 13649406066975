import styled from 'styled-components';

export const Container = styled.div`
  p {
    font-size: 0.87rem;
    font-weight: 800;
    letter-spacing: 0.05rem;
    line-height: 1.6rem;
    color: ${p => p.color};

    a {
      color: var(--color-purple-dark);
      transition: all 0.3s ease;

      &:hover {
        color: var(--color-purple-dark-2);
      }
    }
  }

  &.subtitle {
    p {
      font-size: 0.7rem;
      font-weight: 800;
      letter-spacing: 0.05rem;
      line-height: 1.6rem;
      color: ${p => p.color};

      @media (min-width: 768px) {
        font-size: 0.87rem;
        font-weight: 800;
        letter-spacing: 0.05rem;
      }
    }
  }

  &.description {
    p {
      font-family: 'titulos';
      font-size: 1rem;
      line-height: 1.44rem;
      letter-spacing: 0.04rem;
      font-weight: 800;
      color: var(--color-pink-description);

      @media (min-width: 768px) {
        font-family: 'titulos';
        font-size: 2rem;
        line-height: 2.8rem;
        letter-spacing: 0.08rem;
      }
    }
  }

  &.normal {
    p {
      font-weight: 300 !important;
    }
  }
`;
