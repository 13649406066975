import React from 'react';
import { Footer, Description, HeaderVagas } from '../../components';
import { useResize } from '../../hooks';
import { ContainerFluidMain, ContainerFluid, Container, WrapperContent } from './index.styled';

const Vaga58TechleadPage = () => {
  const { isMobile } = useResize();
  const textMarginDesktop = '20px';

  return (
    <ContainerFluidMain>
        <ContainerFluid id="vaga" className="fluid">
          <Container>
            <HeaderVagas />
            <WrapperContent>

              <div className="wrapper-title">
                <p className="destaque title-purple smaller">
                  Oportunidade: Techlead (.NET)
                </p>
              </div>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Aqui na Engenhos nos empenhamos em construir um ambiente no qual possamos ser felizes trabalhando com o que gostamos, tecnologia, e trabalhando como time conseguiremos fazer mais e melhor.
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Estamos buscando pessoas de qualquer parte do Brasil para atuar como Tech Lead em Home Office.  Estamos procurando por pessoas propositivas e que gostem de buscar novos conhecimentos.
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Para nós, o líder não é chefe, mas sim parte do time, é o profissional que trabalha na equipe para maximizar a eficiência e eficácia de entregas de software e disseminar práticas ágeis.
              </Description>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                SUAS RESPONSABILIDADES INCLUEM
              </Description>
              <ul>
                <li>
                Ser a referência técnica para os desenvolvedores com menor senioridade
                </li>
                <li>
                1:1 e desenvolvimento de carreira com o time
                </li>
                <li>
                Protagonizar as práticas ágeis
                </li>
                <li>
                Zelar pela qualidade e garantia da entrega do software
                </li>
                <li>
                Tirar impedimentos dos desenvolvedores
                </li>
                <li>
                Participar ativamente nas definições técnicas e de negócio
                </li>
                <li>
                Participar ativamente do dia a dia de times multidisciplinares
                </li>
                <li>
                Auxiliar na identificação de gargalos e problemas nos fluxos de trabalho e processos
                </li>
                <li>
                Conduzir cerimônias
                </li>
                <li>
                Auxiliar PO na gestão do backlog
                </li>
                <li>
                Coleta e análise das métricas de produtividade do time
                </li>
                <li>
                Monitorar e atuar para que exista backlog preparado para o time (fluxo contínuo)
                </li>
                <li>
                Identificar necessidades de treinamentos/capacitação para o time
                </li>
              </ul>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                REQUISITOS
              </Description>
              <ul>
                <li>
                Ter experiência em plataforma .NET
                </li>
                <li>
                Ter experiência com banco de dados relacionais e não relacionais
                </li>
                <li>
                Estar familiarizado com cloud e suas soluções, preferencialmente Azure
                </li>
                <li>
                Experiência como Tech Lead em times ágeis
                </li>
                <li>
                Ser agnóstico no que tange à métodos, frameworks, metodologias, ferramentas, etc.
                </li>
                <li>
                Experiência com técnicas de escritas de histórias de usuário e de facilitação de dinâmicas
                </li>
                <li>
                Conhecimento em Azure Devops
                </li>
              </ul>

              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                PARA SE CANDIDATAR
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Envie seu currículo para <a href="mailto:eliane.pimentel@engenhos.net">eliane.pimentel@engenhos.net</a>
              </Description>
            </WrapperContent>
          </Container>
        </ContainerFluid>
      <Footer />
    </ContainerFluidMain>
  );
};

export default Vaga58TechleadPage;
