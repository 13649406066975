import React from 'react';
import { useResize } from '../../hooks';
import { Description } from '..';
import { ContainerFluid, Container, WrapperContent } from './index.styled';

const PoliticaComponent = () => {
  const { isMobile } = useResize();

  const textMarginDesktop = '20px';
  return (
    <ContainerFluid id="politica" className="fluid">
      <Container>
        <WrapperContent>
          <div className="wrapper-title">
            <p className="destaque title-purple smaller">
              POLÍTICA DE PRIVACIDADE DE DADOS
            </p>
          </div>
          {/*
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            BASE JURÍDICA PARA O PROCESSAMENTO
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            Nos termos da lei, o <b>CONTROLADOR</b> poderá realizar o tratamento
            de Dados nas seguintes hipóteses:
          </Description>
          <ul>
            <li>
              <em>i</em>. existência de consentimento pelo Titular;
            </li>
          </ul>
          */}
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            1. INTRODUÇÃO
          </Description>
          <ul>
            <li>
              <em>1.1.</em>Este normativo contém informações que concernem à forma de tratamento dos dados pessoais do usuário pela Nossa Plataforma, de forma total ou parcial, sendo automatizados ou não, realizado pelo sistema, bem como os que poderão ou não serem armazenados.
            </li>
            <li>
              <em>1.2.</em>O intuito deste documento é informar e orientar no que diz respeito às informações coletadas, o motivo das referidas coletas e como o usuário poderá gerenciar, atualizar, exportar ou excluir essas informações.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            2. PILARES LEGAIS E NORMATIVOS
          </Description>
          <ul>
            <li>
              <em>2.1.</em>A presente Política de Privacidade e de Dados está em conformidade com a Lei nº 13.709/2018, que diz respeito à Lei Geral de Proteção de Dados Pessoais (LGPD).
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            3. MUDANÇAS NESTA POLÍTICA DE PRIVACIDADE E DADOS
          </Description>
          <ul>
            <li>
              <em>3.1.</em>Esta Política poderá sofrer alterações em decorrência de alguma eventual atualização normativa, motivo pelo qual torna-se imperativo a consulta do usuário de forma periódica a este documento.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            4. DEFINIÇÕES
          </Description>
          <ul>
            <li>
              <em>4.1</em>Para fins desta Política, consideram-se as seguintes definições:
            </li>
            <li>
              <em>4.1.1.</em><b>BANCO DE DADOS:</b> conjunto estruturado de dados, em um ou inúmeros volumes, armazenado de forma física ou virtual;
            </li>
            <li>
              <em>4.1.2.</em><b>CONSENTIMENTO:</b> manifestação voluntária e livre sobre a concordância com o tratamento de dados;
            </li>
            <li>
              <em>4.1.3.</em><b>CONTROLADOR:</b> pessoa natural ou jurídica, privada ou pública, competente pelas decisões acerca do tratamento de dados, seu processamento e utilização;
            </li>
            <li>
              <em>4.1.4.</em><b>DADOS:</b> informações identificadas ou identificáveis;
            </li>
            <li>
              <em>4.1.5.</em><b>ELIMINAÇÃO:</b> exclusão de dado ou conjunto de dados do banco de dados;
            </li>
            <li>
              <em>4.1.6.</em><b>OPERADOR:</b> pessoa natural ou jurídica, privada ou pública, que realiza o tratamento dos dados;
            </li>
            <li>
              <em>4.1.7.</em><b>PLATAFORMA:</b> websites mantidos por Nós, no qual Você pode acessar os Serviços prestados;
            </li>
            <li>
              <em>4.1.8.</em><b>TITULAR:</b> proprietário dos dados, o qual os fornece de livre e espontânea vontade ao <b>CONTROLADOR</b>;
            </li>
            <li>
              <em>4.1.9.</em><b>TRATAMENTO:</b> toda operação realizada com dados pessoas, como coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle de informação, modificação, comunicação, transferências, difusão ou extração; e
            </li>
            <li>
              <em>4.1.10.</em><b>USUÁRIO:</b> utilizador da Plataforma mantida e fornecida pela <b>ENGENHOS</b>.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            5. CONTROLADOR DE DADOS
          </Description>
          <ul>
            <li>
              <em>5.1.</em>Para fins desta Política de Privacidade, de Nossos Serviços e Produtos, o <b>CONTROLADOR</b> será a <b>ENGENHOS SOLUÇÕES EM TECNOLOGIA LTDA (“ENGENHOS”)</b>, devidamente registrada sob o CNPJ nº 30.032.481/0001-88, tendo o DPO para contato sendo:
              <p className="center">
                <b>CONTATO@ZR.LEGAL</b>
                <br />
                <b>JURÍDICO</b>
              </p>
            </li>
            <li>
              <em>5.2.</em>Visando facilitar o exercício dos Seus Direitos, é possível nos enviar quaisquer questões sobre a Lei Geral de Proteção de Dados Pessoais (LGPD) através do nosso canal de comunicação criado unicamente para este fim, o qual é acessado <a href="https://forms.gle/dC8pRWmeZV38G1z56" target="_blank">neste link</a>.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            6. MODO E LOCAL DE PROCESSAMENTO DOS DADOS
          </Description>
          <ul>
            <li className="destaque">
              <em>6.1.</em>MÉTODO DE PROCESSAMENTO
            </li>
            <li>
              <em>6.1.1.</em><b>O CONTROLADOR</b> tomará as medidas de segurança adequadas para impedir o acesso não autorizado, divulgação, alteração ou destruição não autorizada dos Dados.
            </li>
            <li>
              <em>6.1.2.</em>Todo processamento realizado utilizará as melhores tecnologias e ferramentas disponíveis e possíveis para a questão; serão estabelecidos procedimentos organizacionais e meios que garantam o acesso aos Dados apenas por indivíduos que necessitem praticar tal ação para possibilitar a prestação dos serviços. Assim, além do <b>CONTROLADOR</b>, os Dados poderão ser acessados por seus colaboradores e/ou terceiros externos que estejam vinculados ao serviço.
            </li>
            <li className="destaque">
              <em>6.2.</em>BASE JURÍDICA PARA O PROCESSAMENTO
            </li>
            <li>
              <em>6.2.1.</em>Nos termos da lei, o <b>CONTROLADOR</b> poderá realizar o tratamento de Dados nas seguintes hipóteses:
              <ul>
                <li>
                  <em>i.</em>existência de consentimento pelo Titular;
                </li>
                <li>
                  <em>ii.</em>cumprimento de obrigação legal ou regulatória;
                </li>
                <li>
                  <em>iii.</em>a execução de um contrato e seus procedimentos preliminares, nos casos em que o Titular faça parte do negócio;
                </li>
                <li>
                  <em>iv.</em>o exercício de nossos direitos em processos judiciais, administrativos ou de arbitragem;
                </li>
                <li>
                  <em>v.</em>proteção ou segurança física do Titular ou de um terceiro; e
                </li>
                <li>
                  <em>vi.</em>para atender os nossos interesses legítimos, desde que seus direitos e liberdades fundamentais não prevaleçam sobre tais interesses.
                </li>
              </ul>
            </li>
            <li>
              <em>6.2.2.</em>Caso a caso, o <b>TITULAR</b> poderá ficar à vontade para solicitar esclarecimentos ao <b>CONTROLADOR</b> sobre qual dispositivo legal aplica-se a sua situação em concreto.
            </li>
            <li className="destaque">
              <em>6.3.</em>LUGAR
            </li>
            <li>
              <em>6.3.1.</em>O <b>CONTROLADOR</b> realiza todo o processamento e tratamento diretos na sua sede, em território nacional; ainda, é possível que exista o processamento em locais terceiros quando há vinculação de fornecedores para a concretização dos serviços aqui oferecidos.
            </li>
            <li>
              <em>6.3.2.</em>Como o <b>CONTROLADOR</b> não consegue, sob seus próprios esforços, realizar todas as atividades que se fazem necessárias para a boa fruição de seus Serviços, existe a transferência de Dados para fora do território nacional. Este fato é preciso para que se possa utilizar de plataformas especializadas em demandas de nossos Usuários, bem como para garantir o armazenamento de nosso banco de dados da forma mais segura frente à tecnologia disponível na atualidade.
            </li>
            <li className="destaque">
              <em>6.4.</em>TRANSFERÊNCIA DE INFORMAÇÕES PESSOAIS PARA FORA DO BRASIL
            </li>
            <li>
              <em>6.4.1.</em>A LGPD permite a transferência dos Dados para territórios internacionais quando
              <ul>
                <li>
                  <em>i.</em>a autoridade nacional autorizar a transferência;
                </li>
                <li>
                  <em>ii.</em>o titular tiver fornecido o seu consentimento específico e em destaque para a transferência, com informação prévia sobre o caráter internacional da operação, distinguindo claramente de outras finalidades; ou
                </li>
                <li>
                  <em>iii.</em>necessário para atender às hipóteses previstas nos incisos II, V e VI do artigo 7º da LGPD.
                </li>
              </ul>
            </li>
            <li>
              <em>6.4.2.</em>Considerando os pontos trazidos neste tópico, bem como no anterior, o <b>USUÁRIO</b> concede o seu expresso consentimento acerca da transferência de seus dados para o exterior. Não havendo concordância com esta prática, o <b>TITULAR</b> deverá nos comunicar para que as devidas providências sejam tomadas.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            7. COMO OBTEMOS SEUS DADOS E QUAIS DADOS SÃO TRATADOS
          </Description>
          <ul>
            <li>
              <em>7.1.</em>A vasta maioria dos Dados armazenados e tratados por nós são recebidos através do preenchimento voluntário, pelo <b>TITULAR</b>, de nossos formulários, cadastros e informativos; ressaltamos que a entrega voluntária de dados pelo Usuário poderá ser manejada em diversos momentos e por diferentes maneiras, contando-se desde o contato inicial com a Plataforma, assinaturas de contratos, termos, acordos e quaisquer tipos de documentos e, precipuamente, pela realização de compras. Por derradeiro, os dados a serem armazenados e tratados proverão desta inclusão consensual e voluntária realizada pelos usuários, não sendo, Nossa Plataforma, nenhum tipo de coletor de dados.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            8. FONTES DE DADOS PESSOAIS
          </Description>
          <ul>
            <li>
              <em>8.1.</em>Nós realizaremos a coleta dos Dados através das seguintes formas:
              <ul>
                <li>
                  <em>i.</em><b>NOSSA PLATAFORMA:</b> todos os nossos websites poderão ser utilizados para a coleta de dados pessoais; tal questão diz respeito diretamente ao nosso domínio e endereço de IP, bem como aos sites e páginas de terceiros com os quais Nós estabelecemos serviços;
                </li>
                <li>
                  <em>ii.</em><b>CORREIOS ELETRÔNICOS E/OU SISTEMAS/APLICATIVOS PARA TROCA DE MENSAGENS INSTANTÂNEAS:</b> todos os serviços utilizados para manter comunicações eletrônicas entre Você e Nós, incluindo aqueles feitos por Nós ou por terceiros;
                </li>
                <li>
                  <em>iii.</em><b>CENTRAL DE VENDAS E ATENDIMENTO:</b> todas as comunicações realizadas com os Usuários, através de centrais mantidas por Nós ou de serviços contratados;
                </li>
                <li>
                  <em>iv.</em><b>ANÚNCIOS, PROPAGANDAS E FORMULÁRIOS:</b> todas e quaisquer interações com anúncios, pagos ou não, propagandas e formulários feitos e veiculados por Nós, sejam eles online ou off-line;
                </li>
                <li>
                  <em>v.</em><b>REGISTROS OFFLINE:</b> registros preenchidos offline, distribuídos da forma que forem, seja por Nós ou por terceiros; e
                </li>
                <li>
                  <em>vi.</em><b>DADOS RECEBIDOS DE TERCEIROS:</b> incluem-se, mas não se limitam, às redes sociais e sites de terceiros, bem como serviços que agreguem aos Nossos parceiros, fornecedores, patrocinadores, fontes públicas e demais dados recebidos.
                </li>
              </ul>
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            9. QUAIS SÃO SEUS DADOS PESSOAIS QUE COLETAMOS
          </Description>
          <ul>
            <li>
              <em>9.1.</em>Durante a Sua interação junto a Nossa Plataforma, poderemos coletar vários tipos de Dados pessoais, dentro dos ditames previamente estipulados neste documento, conforme os seguintes exemplos:
            </li>
            <li>
              <em>9.1.1.</em><b>INFORMAÇÕES DE CONTATO:</b> todas e quaisquer informações que possam facilitar a Nossa comunicação com Você, desde que efetivamente necessárias, incluindo o seu endereço físico de correspondência, números de telefone, WhatsApp, redes sociais, e-mail e sites;
            </li>
            <li>
              <em>9.1.2.</em><b>INFORMAÇÕES DEMOGRÁFICAS E SEUS INTERESSES:</b> informações que possam descrever seus dados demográficos, hábitos ou suas características de comportamento, incluindo itens como seu aniversário, data de nascimento, idade ou faixa etária, gênero, localização geográfica, produtos favoritos, hobbies e passatempos, demais interesses e informações familiares ou sobre seu estilo de vida;
            </li>
            <li>
              <em>9.1.3.</em><b>INFORMAÇÕES TÉCNICAS SOBRE SEUS EQUIPAMENTOS COMPUTACIONAIS OU DISPOSITIVOS MÓVEIS:</b> detalhes sobre o seu computador ou outro dispositivo portátil utilizado para acessar Nossa Plataforma, incluindo o registro do endereço IP, sua localização geográfica, o tipo e a versão de sistema operacional e o tipo e a versão do navegador da web. Se Você acessar Nossa Plataforma usando um dispositivo móvel, como um smartphone ou tablet, as informações coletadas também incluirão, sempre que permitido, o ID de dispositivo exclusivo de seu aparelho, a localização geográfica e outros dados similares do dispositivo móvel;
            </li>
            <li>
              <em>9.1.4.</em><b>INFORMAÇÕES SOBRE COMO VOCÊ UTILIZA NOSSA PLATAFORMA E SERVIÇOS:</b> enquanto Você interage Conosco e Nossa Plataforma, utilizaremos tecnologias para que seja possível coletar automaticamente dados que Nos demonstre a Sua forma de utilizar os Nossos Serviços. Tais fatos poderão incluir acessos em links, páginas e conteúdos visitados por Você, bem como por quanto tempo, e demais informações sobre o seu comportamento frente Nossos Serviços. Estes fatos serão capturados através de tecnologias automatizadas, sejam elas cookies ou integrações com terceiros, e Você poderá se opor à utilização;
            </li>
            <li>
              <em>9.1.5.</em><b>INFORMAÇÕES DE REDES SOCIAIS DE TERCEIROS:</b> dados que Você compartilha publicamente em uma rede social de terceiros ou informações que fazem parte de seu perfil em uma rede social de terceiros, desde que exista a permissão de compartilhamento Conosco. Tais dados podem incluir, mas não se limitar, às informações básicas de Sua conta, bem como demais informações ou atividades que tenham o seu compartilhamento permitido por Você; e
            </li>
            <li>
              <em>9.1.6.</em><b>INFORMAÇÕES FINANCEIRAS E DE PAGAMENTO:</b> todos e quaisquer pagamentos realizados por Você ocorrerão diretamente em plataformas desvinculadas da Nossa Plataforma; assim, não serão armazenados nenhum tipo de dados desta natureza por Nós.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            10.	SOBRE OS DADOS PESSOAIS DE CRIANÇAS E ADOLESCENTES
          </Description>
          <ul>
            <li>
              <em>10.1.</em>Nós não solicitamos, coletamos e/ou tratamos dados pessoais de crianças e adolescentes, compreendemos como um dos pontos essenciais para os Usuários que utilizam a nossa Plataforma, Serviços e Produtos sendo a maioridade legal, capacidade civil e possibilidade de responsabilização por atos próprios. Caso sejam verificados dados pessoais desta estirpe dentro do Nosso tratamento, estes serão devidamente removidos de todas as Nossas bases de dados.
            </li>
            <li>
              <em>10.2.</em>Porventura, caso o Nosso modo de funcionamento seja alterado e começarmos a vislumbrar a possibilidade de realizar a coleta e tratamento de dados pessoais de crianças e adolescentes, este fato somente será realizado através dos responsáveis legais de cada criança e adolescente.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            11.	PERÍODO DE CONSERVAÇÃO
          </Description>
          <ul>
            <li>
              <em>11.1.</em>	Os Dados serão processados e armazenados conforme o lapso temporal que se fizer necessário para o cumprimento das obrigações que culminaram em sua coleta. Portanto, os Dados coletados para:
              <ul>
                <li>
                  <em>i.</em>a execução de um contrato entre o <b>CONTROLADOR</b> e o Usuário, sendo conservados pelo período mínimo legal de 5 (cinco) anos, podendo, posteriormente, tornarem-se anônimos e/ou serem excluídos;
                </li>
                <li>
                  <em>ii.</em>dados pessoais utilizados para fornecer uma experiência personalizada à Você serão mantidos exclusivamente pelo tempo permitido; e
                </li>
                <li>
                  <em>iii.</em>as realizações dos legítimos interesses do <b>CONTROLADOR</b> e serão conservados pelo tempo que for necessário.
                </li>
              </ul>
            </li>
            <li>
              <em>11.2.</em>Havendo permissão e consentimento do <b>TITULAR</b>, o <b>CONTROLADOR</b> poderá aumentar o tempo de armazenamento e conservação dos dados; bem como, sendo superveniente alguma decisão judicial, administrativa ou nova legislação que assim o obrigar a fazer, também haverá prorrogação no armazenamento dos dados.
            </li>
            <li>
              <em>11.3.</em>Ao término do período de armazenamento, os Dados serão anonimizados ou excluídos com os meios técnicos e viáveis possíveis, tornando inviável o exercício dos direitos de acessar, apagar, corrigir e solicitar portabilidade.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            12.	FINALIDADE DO TRATAMENTO DOS DADOS PESSOAIS
          </Description>
          <ul>
            <li>
              <em>12.1.</em>Para que possamos manter uma relação direta com Você, bem como realizar os serviços para os quais fomos contratados, temos a necessidade de coletar, tratar e armazenar alguns dados pessoais seus. Portanto, o armazenamento e tratamento de dados é realizado única e exclusivamente com o consentimento do usuário, o qual encaminha, voluntariamente, os seus dados à <b>ENGENHOS</b>, sendo respaldado pelo artigo 7º, I, da LGPD.
            </li>
            <li>
              <em>12.2.</em>O tratamento poderá ter como função, ainda, a otimização da usabilidade e funcionamento da plataforma, a prevenção de fraudes e a mensuração de satisfação com os nossos serviços, porém de forma sempre pessoal e vinculada ao usuário que realizou o envio de determinada informação – nunca realizaremos o aproveitamento de dados de um usuário para outro. Por fim, a Plataforma poderá utilizar os dados com propósito de marketing e comunicação, incluindo, mas não se limitando, a divulgação de produtos, serviços, promoções e eventos através de quaisquer um dos meios de comunicação fornecidos pelo usuário; todos os envios terão a opção de cancelamento, o que será providenciado no menor tempo possível.
            </li>
            <li>
              <em>12.3.</em>Os itens a seguir descrevem as finalidades para as quais Nós coletamos os seus Dados Pessoais, e os diferentes tipos de Dados Pessoais que coletamos para cada finalidade. Note, por favor, que nem todos os usos abaixo serão relevantes para todos os indivíduos e podem se aplicar apenas a situações específicas.
              <ul>
                <li>
                  <em>i.</em><b>PRESTAÇÃO DE NOSSOS SERVIÇOS:</b> utilizaremos os seus Dados para a realização de nossos serviços nos termos que tenhamos combinado com Você.
                  <ul>
                    <li>
                      <em class="bullet"></em><b>motivo para uso dos seus dados pessoais nessa situação:</b>
                    </li>
                    <li>
                      <em>a)</em>formalização da negociação;
                    </li>
                    <li>
                      <em>b)</em>personalização dos serviços dentro das suas expectativas;
                    </li>
                    <li>
                      <em>c)</em>comunicação rotineira entre Nós; e
                    </li>
                    <li>
                      <em>d)</em>nossos interesses legítimos, tais como:
                      <ul>
                        <li>
                          <em>1.</em>melhorar continuamente os Nossos produtos e serviços; ou
                        </li>
                        <li>
                          <em>2.</em>melhorar continuamente a efetividade do Nosso atendimento ao consumidor.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <em>ii.</em><b>ATENDIMENTO AOS SEUS PEDIDOS:</b> utilizamos os seus Dados para que possamos processar as suas compras, assinaturas e pedidos dentro de Nossa Plataforma.
                  <ul>
                    <li>
                      <em class="bullet"></em><b>motivo para uso dos seus dados pessoais nessa situação:</b>
                    </li>
                    <li>
                      <em>a)</em>cumprir obrigações contratuais;
                    </li>
                    <li>
                      <em>b)</em>obrigações legais; e
                    </li>
                    <li>
                      <em>c)</em>Nossos interesses legítimos, como:
                      <ul>
                        <li>
                          <em>1.</em>melhorar e desenvolver novos Produtos e Serviços;
                        </li>
                        <li>
                          <em>2.</em>ser mais eficientes no atendimento aos seus pedidos;
                        </li>
                        <li>
                          <em>3.</em>proteger nossos sistemas, redes e colaboradores; ou
                        </li>
                        <li>
                          <em>4.</em>cumprir integralmente as obrigações legais.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <em>iii.</em><b>REALIZAÇÃO DE CONCURSOS, PROMOÇÕES E DEMAIS AÇÕES DE MARKETING:</b> com seu consentimento, quando preciso for, Nós utilizaremos os Dados para fornecer informações acerca de Nossos Produtos ou Serviços; tais como comunicações de marketing, campanhas publicitárias, promoções etc. Nesta situação, o uso de seus Dados é completamente voluntário, o que significa que Você pode se opor, ou mesmo retirar seu consentimento a qualquer momento, ao processamento de seus Dados Pessoais para estas finalidades.
                  <ul>
                    <li>
                      <em class="bullet"></em><b>motivo para uso dos seus dados pessoais nessa situação:</b>
                    </li>
                    <li>
                      <em>a)</em>cumprir obrigações contratuais;
                    </li>
                    <li>
                      <em>b)</em>Nossos interesses legítimos, tais quais:
                      <ul>
                        <li>
                          <em>1.</em>entender quais de Nossos produtos e serviços podem interessar a Você e fornecer informações sobre eles; ou
                        </li>
                        <li>
                          <em>2.</em>definir consumidores para novos produtos ou serviços.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <em>iv.</em><b>REDES SOCIAIS E SITES DE TERCEIROS:</b> usamos seus Dados Pessoais quando ocorre a interação com funções em redes sociais e/ou sites de terceiros, para que possamos compreender o seu perfil de Usuário.
                  <ul>
                    <li>
                      <em class="bullet"></em><b>motivo para uso dos seus dados pessoais nessa situação:</b>
                    </li>
                    <li>
                      <em>a)</em>obtivemos o seu consentimento (quando necessário); ou
                    </li>
                    <li>
                      <em>b)</em>Nossos interesses legítimos, tais como:
                      <ul>
                        <li>
                          <em>1.</em>entender quais de Nossos Produtos e Serviços podem interessar, bem como fornecer informações sobre eles; ou
                        </li>
                        <li>
                          <em>2.</em>definir consumidores para novos Produtos ou Serviços.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <em>v.</em><b>OUTRAS FINALIDADES E SITUAÇÕES EM GERAL:</b> De acordo com a legislação vigente, Nós utilizamos seus Dados Pessoais para outras finalidades gerais de negócio, como fazer manutenção em sua conta, conduzir pesquisas internas ou de mercado e medir a efetividade de nossas campanhas publicitárias.
                  <ul>
                    <li>
                      <em class="bullet"></em><b>motivo para uso dos seus dados pessoais nessa situação:</b>
                    </li>
                    <li>
                      <em>a)</em>obtivemos o seu consentimento (quando necessário); ou
                    </li>
                    <li>
                      <em>b)</em>obrigações legais; ou
                    </li>
                    <li>
                      <em>c)</em>Nossos interesses legítimos, tais como:
                      <ul>
                        <li>
                          <em>1.</em>melhorar e desenvolver novos Produtos e Serviços;
                        </li>
                        <li>
                          <em>2.</em>ser mais eficientes no atendimento aos seus pedidos;
                        </li>
                        <li>
                          <em>3.</em>proteger os Nossos sistemas, redes e colaboradores; ou
                        </li>
                        <li>
                          <em>4.</em>cumprir integralmente com obrigações legais.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            13.	INFORMAÇÕES DETALHADAS SOBRE O PROCESSAMENTO DE DADOS PESSOAIS
          </Description>
          <ul>
            <li>
              <em>13.1.</em>Todas as interações são realizadas única e exclusivamente para viabilizar a prestação de serviços pelo <b>CONTROLADOR</b>; cada usuário, tendo interesse, poderá questionar a funcionalidade de cada uma delas e verificar a possibilidade de interromper tal ato. Em geral, os seus Dados serão compartilhados com:
              <ul>
                <li>
                  <em>i.</em><b>PROVEDORES DE SERVIÇOS:</b> são as empresas externas que auxiliam na prestação de Nossos Serviços, viabilizando a Nossa operação; estes terceiros são escolhidos com base em critérios técnicos, de competência e segurança, estando autorizados a operar dentro dos limites que, por Nós, forem impostos.
                </li>
                <li>
                  <em>ii.</em><b>PARCEIROS COMERCIAIS:</b> Nós realizaremos o compartilhamento dos Seus dados com alguns de Nossos parceiros comerciais, os quais poderão, apenas nos limites dos assuntos tidos como interessantes por Vocês, acioná-los para questões vinculadas à marketing, publicidade e propaganda;
                </li>
                <li>
                  <em>iii.</em><b>TERCEIROS QUE USAM SEUS DADOS PESSOAIS POR MOTIVOS LEGAIS:</b> estamos obrigados legalmente a compartilhar os seus Dados com terceiros quando assim for necessário.
                </li>
              </ul>
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            14.	MEDIDAS ADOTADAS PARA CUIDAR DA SUA SEGURANÇA
          </Description>
          <ul>
            <li>
              <em>14.1.</em>Nós adotamos medidas técnicas e administrativas para que os seus Dados sejam mantidos de forma confidencial e segura; entretanto, tais proteções não serão aplicáveis em situações nas quais Você tenha optado por compartilhar, em áreas públicas, as Suas informações. Algumas de nossas medidas são:
              <ul>
                <li>
                  <em>i.</em><b>PESSOAS QUE PODEM ACESSAR SEUS DADOS PESSOAIS:</b> somente as pessoas físicas ou jurídicas que precisem, para cumprir os propósitos para os quais os seus Dados foram coletados, terão direito a acessá-los;
                </li>
                <li>
                  <em>ii.</em><b>MEDIDAS QUE ESPERAMOS QUE VOCÊ CUMPRA:</b> esperamos que você crie senhas seguras, fortes e mantenham-nas devidamente armazenadas longe do acesso de terceiros; tais informações não deverão ser compartilhadas com demais indivíduos;
                </li>
                <li>
                  <em>iii.</em><b>TRANSFERÊNCIA DE SEUS DADOS PESSOAIS:</b> para viabilizar o Nosso funcionamento, iremos realizar a transferência de seus Dados para terceiros, sempre com finalidades definidas, que estejam situados dentro ou fora do Brasil.
                </li>
              </ul>
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            15.	DOS PRINCÍPIOS NORTEADORES DO TRATAMENTO DE DADOS
          </Description>
          <ul>
          <li>
              <em>15.1.</em>A plataforma  se compromete a cumprir as normas previstas em Lei, em respeito aos seguintes princípios:
              <ul>
                <li>
                  <em>i.</em>os dados pessoais serão processados de forma lícita, leal e transparente;
                </li>
                <li>
                  <em>ii.</em>os dados pessoais do usuário serão coletados apenas para finalidades determinadas, explícitas e legítimas, não podendo ser tratados posteriormente de forma incompatível com essas finalidades;
                </li>
                <li>
                  <em>iii.</em>os dados pessoais do usuário são coletados de forma adequada, pertinente e limitada às necessidades do objetivo para os quais eles são processados;
                </li>
                <li>
                  <em>iv.</em>os dados pessoais do usuário serão exatos e atualizados sempre que necessário, de maneira que os dados inexatos sejam apagados ou retificados quando possível;
                </li>
                <li>
                  <em>v.</em>os dados pessoais do usuário serão conservados de uma forma que permita a identificação dos titulares dos dados apenas durante o período necessário para as finalidades para as quais são tratados; e
                </li>
                <li>
                  <em>vi.</em>os dados pessoais do usuário serão tratados de forma segura, protegidos do tratamento não autorizado ou ilícito e contra a sua perda, destruição ou danificação acidental, adotando as medidas técnicas ou organizativas adequadas.
                </li>
              </ul>
              <li>
                <em>15.1.</em>15.2.	Paralelamente, a <b>PLATAFORMA</b> respeitará todos os direitos garantidos às pessoas físicas e jurídicas que estão respaldados pela legislação nacional e internacional, operando sempre com cuidado e cautela, de forma a mitigar quaisquer riscos e exposições desnecessárias.
              </li>
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            16.	OS DIREITOS DOS USUÁRIOS
          </Description>
          <ul>
          <li>
              <em>16.1.</em>Nos termos da legislação atual, os Usuários possuem certos direitos a respeito dos Dados coletados, armazenados e tratados pelo <b>CONTROLADOR</b>.<br/>
              <ul>
                <li>São direitos assegurados por lei:</li>
                <li>
                  <em>i.</em>obter confirmação sobre a existência de atividades de processamento de seus Dados;
                </li>
                <li>
                  <em>ii.</em>acesso a suas informações pessoais;
                </li>
                <li>
                  <em>iii.</em>solicitar a correção de seus Dados incompletos, inexatos ou desatualizados;
                </li>
                <li>
                  <em>iv.</em>solicitar a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos e/ou tratados em desconformidade com a Lei;
                </li>
                <li>
                  <em>v.</em>requerer a portabilidade de seus dados para outro fornecedor de serviços ou produtos, mediante requisição expressa;
                </li>
                <li>
                  <em>vi.</em>questionar acerca da possibilidade de não fornecer consentimento e suas consequências;
                </li>
                <li>
                  <em>vii.</em>solicitar a eliminação dos dados tratados com consentimento, salvo nas hipóteses previstas no artigo 16 da LGPD;
                </li>
                <li>
                  <em>viii.</em>retirar sua anuência a qualquer momento; e
                </li>
                <li>
                  <em>ix.</em>registrar uma reclamação com relação a suas informações pessoais à ANPD (Autoridade Nacional de Proteção de Dados) ou aos órgãos de proteção ao consumidor.
                </li>
              </ul>
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            17.	QUAIS SÃO SUAS ESCOLHAS SOBRE COMO UTILIZAMOS E DIVULGAMOS SEUS DADOS PESSOAIS
          </Description>
          <ul>
            <li>
              <em>17.1.</em>Nós fazemos o máximo para garantir que Você tenha a maior liberdade de escolha sobre os seus Dados Pessoais; assim, os seguintes mecanismos de gestão para controle lhe são ofertados:
            </li>
            <li>
              <em>17.2.</em><b>COOKIES OU TECNOLOGIAS SIMILARES: </b>
              <ul>
                <li>
                  <em>a)</em>Nossas soluções de gerenciamento de consentimento;
                </li>
                <li>
                  <em>b)</em>as configurações do seu navegador para recusar alguns ou todos os Cookies e tecnologias similares.
                </li>
              </ul>
            </li>
            <li>
              <em>17.3.</em><b>PUBLICIDADE, MARKETING E PROMOÇÕES:</b> Você poderá remover o seu consentimento, a qualquer momento, acerca da Nossa utilização dos seus Dados para este tipo de comunicação, entrando em contato Conosco.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            18.	DO ACESSO AOS DADOS ARMAZENADOS
          </Description>
          <ul>
            <li>
              <em>18.1.</em><b>O TITULAR</b> dos dados poderá, a qualquer momento, solicitar informações acerca da:
              <ul>
                <li>
                  <em>i</em>finalidade específica do tratamento realizado;
                </li>
                <li>
                  <em>ii</em>forma e duração do tratamento realizado;
                </li>
                <li>
                  <em>iii</em>identificação do controlador e suas informações de contato, bem como as informações de uso dos dados realizado por este; e
                </li>
                <li>
                  <em>iv</em>informações sobre as responsabilidades dos agentes realizando o tratamento.
                </li>
              </ul>
            </li>
            <li>
              <em>18.2.</em>Ademais, o <b>TITULAR</b> poderá pleitear:
              <ul>
                <li>
                  <em>i</em>a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais;
                </li>
                <li>
                  <em>ii</em>a retificação dos dados incompletos, desatualizados ou inexatos que lhe digam respeito, sem que exista demora injustificada para tanto;
                </li>
                <li>
                  <em>iii</em>o bloqueio, anonimização ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto em lei;
                </li>
                <li>
                  <em>iv</em>a portabilidade de dados a outro fornecedor de serviços ou produtos, mediante requisição expressa e observados os segredos comerciais e operacionais da <b>ENGENHOS</b>;
                </li>
                <li>
                  <em>v</em>a eliminação dos dados tratados, ainda quando tenha sido dado consentimento anterior, respeitando-se os prazos legais e necessários;
                </li>
                <li>
                  <em>vi</em>informações acerca de compartilhamento dos dados com terceiros; e
                </li>
                <li>
                  <em>vii</em>informações sobre a negativa de consentimento ao tratamento e as consequências disto para a operacionalização da plataforma.
                </li>
              </ul>
            </li>
            <li>
              <em>18.3.</em>O <b>TITULAR</b> poderá, também, solicitar os dados pessoais que lhe digam respeito e que tenha fornecido à Plataforma, num formato estruturado, de uso corrente e de leitura automática, quando possível e viável. Ademais, é o direito do Usuário de não ficar sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado, incluindo a definição de perfis que produzam efeitos na sua esfera jurídica ou que o afetem significativamente de forma similar.
            </li>
            <li>
              <em>18.4.</em>Quaisquer solicitações deverão ser feitas de forma expressa, escrita e formal pelo <b>TITULAR</b> direto dos dados, ou seu representante legal, tutor e/ou curador, ao <b>CONTROLADOR</b>, que mantém o seu direito legal de, após o recebimento daquela, informá-lo os motivos pelos quais tal pedido não poderá ser atendido, sejam eles vinculados a questões técnicas, legais ou por não ser o titular da obrigatoriedade em relação ao requerimento.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            19.	DETALHES SOBRE O DIREITO DE RECUSAR O PROCESSAMENTO
          </Description>
          <ul>
            <li>
              <em>19.1.</em>Sempre que o processamento se dê por motivo de interesse público, exercício de autorização oficial ou para finalidades de cumprimento dos interesses legítimos do <b>CONTROLADOR</b>, o <b>TITULAR</b> poderá apresentar motivação para demonstrar a sua objeção ao tratamento sendo realizado.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            20.	COMO EXERCER ESTES DIREITOS
          </Description>
          <ul>
            <li>
              <em>20.1.</em>Havendo quaisquer pontos a serem solucionados, o <b>TITULAR</b> poderá encaminhar as suas ponderações diretamente ao <b>CONTROLADOR</b> através do e-mail <a href="mailto:CONTATO@ZR.LEGAL">CONTATO@ZR.LEGAL</a>. Todas as solicitações serão atendidas com a maior brevidade e transparência possíveis; sendo impossível realizar o atendimento de pronto, Nós informaremos os motivos para tanto e, também, etapas e prazos viáveis para cumprimento.
            </li>
            <li>
              <em>20.2.</em>Quando o <b>TITULAR</b> solicitar o acesso ou confirmação de processamento, deverá informar os pormenores de suas dúvidas e Dados que imagina estarem sendo tratados. Nos casos em que não houver nenhum tipo de tratamento e processamento de Dados do <b>TITULAR</b> sendo realizado por Nós, informaremos com a maior brevidade possível e recomendaremos o contato direto com a Autoridade Nacional de Proteção de Dados (ANPD).
            </li>
            <li>
              <em>20.3.</em>Neste último caso, responderemos no prazo de 15 (quinze) dias a contar do momento de sua solicitação, fornecendo todas as informações sobre a origem de suas informações pessoais, confirmação da existência ou não de registros, quaisquer critérios utilizados para o processamento e as finalidades do processamento, mantendo protegidos nossos segredos comerciais e industriais.
            </li>
            <li>
              <em>20.4.</em>Nas solicitações de retificação, exclusão, anonimização ou bloqueio dos Dados, iremos comunicar prontamente os demais terceiros que estejam em contato na cadeia de processamento e tratamento, salvo nos casos em que tal comunicação se torne inviável e/ou impossível de realização.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            21.	SEGURANÇA NO TRATAMENTO DOS DADOS PESSOAIS DO USUÁRIO
          </Description>
          <ul>
            <li>
              <em>21.1.</em>Para fins desta Política, considera-se como violação aos dados todos os atos que prejudiquem a incolumidade dos dados armazenados no banco de dados, seja proposital ou acidental. Esta violação pode causar destruição, perda, alteração, divulgação, acesso ou visualização de dados por pessoas não autorizadas.
            </li>
            <li>
              <em>21.2.</em>A <b>PLATAFORMA</b>  se compromete a realizar todos os procedimentos possíveis e viáveis para manter a segurança e o sigilo dos dados tratados, adotando medidas técnicas, administrativas, operacionais e expertises nas mais diversas áreas possíveis, a fim de alinhar da melhor maneira o seu processo interno de armazenamento, bem como proteger os dados de acessos não autorizados, ilicitudes, perda, destruição, alterações indevidas e vazamento de quaisquer formas que sejam. Para tanto, levará sempre em conta o nível de segurança adequado aos riscos, os custos de aplicação e a natureza, a esfera, o contexto e os objetivos do tratamento, bem com os riscos, de probabilidade gravidade variável, para os direitos e liberdades do usuário.
            </li>
            <li>
              <em>21.3.</em>Mais dispendiosos que sejam os esforços, a <b>PLATAFORMA</b>  se exime de problemas causados e divulgações realizadas com culpa exclusiva do usuário na utilização desta, como pelo empréstimo do acesso a terceiro não autorizado. A <b>PLATAFORMA</b>  não se responsabiliza pela atuação de terceiros sob quaisquer hipóteses.
            </li>
            <li>
              <em>21.4.</em>Acaso a PLATAFORMA  tome ciência de algum ataque, ou mera tentativa, aos dados armazenados em seu banco de dados, compromete-se a comunicar a autoridade nacional, bem como ao titular dos dados ameaçados, informando-os os pormenores acerca da situação vivida. Nestes, incluem-se:
              <ul>
                <li>
                  <em>i</em>a descrição dos dados afetados;
                </li>
                <li>
                  <em>ii</em>as medidas técnicas tomadas para a proteção destes anteriormente ao problema;
                </li>
                <li>
                  <em>iii</em>os riscos decorrentes da falha; e
                </li>
                <li>
                  <em>iv</em>as medidas que serão tomadas a partir da falha, de forma a garantir um aumento na segurança.
                </li>
              </ul>
            </li>
            <li>
              <em>21.5.</em>Todos os cuidados engendrados pelo <b>CONTROLADOR</b> em assegurar a segurança de seus usuários são realizados desde a prototipação da <b>PLATAFORMA</b> até a sua divulgação ao usuário, garantindo a segurança em todas as etapas de codificação, experimentação e funcionamento desta.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            22.	DAS RELAÇÕES COM TERCEIROS
          </Description>
          <ul>
            <li>
              <em>22.1.</em>A <b>ENGENHOS</b> poderá compartilhar os dados pessoais coletados com seus parceiros como parte do tratamento destes; note-se que tais terceiros terão suas próprias políticas e termos sem que esta <b>PLATAFORMA</b> possua poder discricionário acerca destes. Nenhuma informação, entretanto, será disponibilizada publicamente sem a expressa permissão do usuário, bem como não serão revendidas para big datas e/ou outros terceiros que não possuam vinculação com esta <b>PLATAFORMA</b>. A qualquer momento, o usuário poderá solicitar que os parceiros interrompam a utilização de seus dados.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            23.	DA PROPRIEDADE INTELECTUAL
          </Description>
          <ul>
            <li>
              <em>23.1.</em>A <b>PLATAFORMA</b>, suas estruturas, funcionalidades nela contidas, toda a informação, dados, textos, imagens, vídeos, gráficos e quaisquer outros componentes utilizados nestes são de inteira e total propriedade da <b>ENGENHOS</b>, protegidos por legislação nacional e internacional.
            </li>
            <li>
              <em>23.2.</em>Qualquer reprodução, representação, adaptação ou exploração parcial ou total dos conteúdos, marcas e serviços propostos pela Plataforma, por qualquer meio que seja, sem autorização prévia, expressa e escrita pelos responsáveis legais da <b>ENGENHOS</b>, é estritamente vedada, podendo-se recorrer às medidas cíveis e criminais cabíveis.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            24.	DADOS DE NAVEGAÇÃO (COOKIES)
          </Description>
          <ul>
            <li>
              <em>24.1.</em>A <b>PLATAFORMA</b> recorre às técnicas de cookies, que lhe permitem averiguar as estatísticas e as informações sobre a navegação do usuário. Os cookies são pequenas quantidades de informações armazenadas pelo navegador do usuário, as quais servem para que o servidor se lembre de determinada informação que posteriormente, e unicamente, o servidor que o implementou poderá ler. Podem ser fornecidos, por exemplo, dados sobre o dispositivo utilizado pelo usuário e seu local, horário de acesso, o seu endereço de IP, o navegador, software e hardware utilizados. Esta coleta de informações busca melhorar a navegação, para o conforto do usuário, ao permitir apresentar-lhe serviços personalizados, de acordo com as suas preferências.
            </li>
            <li>
              <em>24.2.</em>Os cookies não permitem que seja realizada a extração, coleta e armazenamento de nenhuma informação constante no disco rígido do usuário, tampouco que sejam acessadas informações pessoais armazenadas por este. Tais tipos de informações só ficarão sob domínio da <b>PLATAFORMA</b> através da entrega, consensual e voluntária, por parte do usuário; nunca haverá esforços em sentido de captação pela própria <b>PLATAFORMA</b>. Acaso os cookies possibilitem a identificação de algum usuário, serão considerados como dados pessoais e, portanto, receberão todas as proteções legais e as disposições trazidas neste documento.
            </li>
            <li>
              <em>24.3.</em>O <b>USUÁRIO</b> possui toda a discricionariedade para gerir os cookies através do uso das configurações de seu navegador, podendo, a qualquer momento, cancelar ou impedir o armazenamento deste. Deverá estar ciente, todavia, que a opção pelo bloqueio aos cookies poderá afetar negativamente o desempenho da plataforma, bem como impedir a disponibilidade de algumas ferramentas dentro dela. Ficará, portanto, ao seu exclusivo critério a opção entre melhor navegabilidade, e consequentemente, disponibilização do armazenamento de cookies.
            </li>
            <li>
              <em>24.4.</em>Nós utilizamos os seguintes tipos de cookies:
            </li>
            <li>
              <em>24.4.1.</em><b>COOKIES DE SESSÃO:</b> possuem o seu ciclo de vida breve, sendo temporários, e excluídos quando Você fecha o seu navegador – estes cookies não reconhecerão Você em seu próximo acesso;
            </li>
            <li>
              <em>24.4.2.</em><b>COOKIES PERSISTENTES:</b> possuem um ciclo de vida brevemente prolongado e permanecerão no seu navegador até a exclusão manual, por Você, ou automática, pelo seu navegador – estes cookies reconhecerão Você em seu próximo acesso;
            </li>
            <li>
              <em>24.4.3.</em><b>COOKIES NECESSÁRIOS:</b> são essenciais para o Nosso funcionamento de forma adequada, porquanto permitem que Você navegue em nosso Website com a melhor usabilidade possível; e
            </li>
            <li>
              <em>24.4.4.</em><b>COOKIES EM NOSSAS PROPAGANDAS:</b> inserimos alguns cookies em nossas publicidades realizadas em websites de terceiros; destes, recebemos informações acerca do Seu acesso, interação ou cliques. Estes cookies serão utilizados para o envio de publicidades e propagandas que condizem com o Seu interesse.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            25.	INFORMAÇÕES ADICIONAIS SOBRE A COLETA E PROCESSAMENTO DE DADOS
          </Description>
          <ul>
            <li className="destaque">
              <em>25.1.</em>AÇÃO JURÍDICA
            </li>
            <li>
              <em>25.2.</em>Havendo necessidade, os Dados poderão ser utilizados para fins jurídicos, em juízo ou não, em situações decorrentes da contratação dos serviços aqui oferecidos. O <b>TITULAR</b> está ciente de que, sendo este o caso, o <b>CONTROLADOR</b> será obrigado a entregar e revelar os Dados às autoridades governamentais.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            26.	DA ATUALIZAÇÃO DO PRESENTE INSTRUMENTO
          </Description>
          <ul>
            <li>
              <em>26.1.</em>A <b>ENGENHOS</b> reserva o seu direito de realizar a alteração desta Política a qualquer momento, conforme lhe for mais conveniente, para abordar mudanças em seus trâmites, inclusões ou supressões de disposições, bem como adequar às normas ao possível novo funcionamento da <b>PLATAFORMA</b>.
            </li>
            <li>
              <em>26.2.</em>Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modificações, deverá solicitar, imediatamente, o cancelamento de sua conta e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            27.	RECLAMAÇÃO A UMA AUTORIDADE DE CONTROLE
          </Description>
          <ul>
            <li>
              <em>27.1.</em>Sem prejuízo de qualquer outra via de recurso judicial ou administrativo, todos os titulares de dados têm direito a apresentar reclamação a uma autoridade de controle, seja à autoridade da sede da plataforma, ou do país de residência habitual do usuário, do seu local de trabalho ou do local onde foi alegadamente praticada a infração, se o <b>TITULAR</b> dos dados considerar que o tratamento dos dados pessoais que lhe digam respeito viola a Lei.
            </li>
            <li>
              <em>27.2.</em>Acaso seja necessário resolver algum tipo de desavença proveniente deste contrato, será aplicável a legislação brasileira e internacional, no que couber a este; para tanto, fica fixado a comarca de São Paulo/SP como foro.
            </li>
          </ul>
          <Description
            style={{
              marginTop: isMobile ? '10px' : { textMarginDesktop },
              textAlign: 'right',
            }}
            fontNormal
            color="#000000"
          >
            Última atualização em 22 de novembro de 2023.
          </Description>
        </WrapperContent>
      </Container>
    </ContainerFluid>
  );
};

export default PoliticaComponent;
