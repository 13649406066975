import React from 'react';
import { useResize } from '../../hooks';
import { CarouselSolucoes, Description } from '..';
import { ContainerFluid, Container, WrapperContent } from './index.styled';

const OQueFazemosComponent = () => {
  const { isMobile } = useResize();

  return (
    <ContainerFluid id="o-que-fazemos" className="fluid">
      <Container>
        <WrapperContent>
          <h2 className="subtitle yellow">O que fazemos</h2>

          <div className="wrapper-title">
            <p className="destaque title-white white-line">
              Soluções sob medida.
            </p>
          </div>
          <Description
            style={{
              marginTop: isMobile ? '10px' : '20px',
              borderBottom: isMobile
                ? 'solid 1px rgba(255,255,255, .4)'
                : 'none',
            }}
            color="#FFF"
          >
            Trabalhamos para que suas iniciativas de TI sejam mais eficientes e
            bem-sucedidas.
          </Description>
        </WrapperContent>
        <CarouselSolucoes />
      </Container>
    </ContainerFluid>
  );
};

export default OQueFazemosComponent;
