import styled from 'styled-components';

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  color: #fff;

  .texts-container {
    margin-top: 10px;

    @media (min-width: 768px) {
      margin-top: 50px;
    }
  }

  @media (min-width: 768px) {
    padding: 0 30px 0 0;
  }

  &[class*='active'] {
    /* background: linear-gradient(-45deg, #ed145b, #7b31f4); */
  }
`;

export const Image = styled.div`
  width: 103px;
  height: 75px;
  background-image: url(${p => p.image});
  background-repeat: no-repeat;
  background-size: auto 100%;
  transform-origin: left;
  transform: scale(0.7);

  @media (min-width: 768px) {
    transform: scale(1);
  }
`;

export const Title = styled.h3`
  display: inline;
  background: var(--color-yellow-2);
  box-shadow: 12px 0 0 var(--color-yellow-2), -8px 0 0 var(--color-yellow-2);
  color: #000;
  font-size: 1.1rem;
  line-height: 0.6rem;
  font-weight: bold;
  letter-spacing: -0.06rem;
  text-transform: uppercase;
  padding: 2px 4px 2px 4px;
  margin-left: 0px;

  @media (min-width: 768px) {
    font-size: 1.6rem;
    line-height: 1.8rem;
    font-weight: bold;
    letter-spacing: -0.06rem;
  }
`;

export const Subtitle = styled.p`
  font-family: 'padrao';
  font-size: 0.82rem;
  letter-spacing: 0.02rem;
  line-height: 1.6rem;
  font-weight: normal;
  width: 100%;
  max-width: calc(70vw);
  height: auto;
  color: var(--color-white);

  @media (min-width: 768px) {
    max-width: 380px;
  }
`;
