/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import Glide, {
  Controls,
  Breakpoints,
  Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';
import { FeatureSolucoes } from '..';
import { GrSeta } from '../../assets';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';
import data from '../../mock-data/carousel-solucoes.json';
import { useResize } from '../../hooks';

import './styles.css';

const CarouselSolucoesComponent = () => {
  const ref = useRef(null);
  const [glide, setGlide] = useState(null);
  const { isMobile } = useResize();
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    setFeatures(data);
  }, []);
  useEffect(() => {
    if (glide !== null) {
      isMobile ? glide.enable() : glide.disable();
      isMobile
        ? glide.update({
            type: 'carousel',
          })
        : glide.update({
            type: 'slider',
          });
    }
  }, [isMobile]);
  useEffect(() => {
    const innerElements = ref?.current.innerHTML;
    const count = (innerElements.match(/<li/g) || []).length;
    if (features.length > 0 && count > 0) {
      if (glide !== null) {
        glide.destroy();
      }
      const glid = new Glide('.glide', {
        breakpoints: {
          768: {
            perView: 1,
          },
          2000: {
            perView: 3,
          },
        },
        peek: 1,
        type: isMobile ? 'carousel' : 'slider',
      }).mount({ Controls, Breakpoints, Swipe });
      setGlide(glid);

      isMobile ? glid.enable() : glid.disable();
    }
  }, [features, isMobile]);
  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        marginTop: isMobile ? '30px' : '80px',
      }}
    >
      <div
        className="glide"
        style={{ pointerEvents: !isMobile ? 'none' : 'auto' }}
      >
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides" ref={ref}>
            {features.length > 0 &&
              features.map(feature => {
                return (
                  <FeatureSolucoes
                    key={feature.id}
                    className="glide__slide"
                    data={feature}
                  />
                );
              })}
          </ul>
        </div>
        <div
          className="controller"
          style={{ display: isMobile ? 'flex' : 'none' }}
        >
          <div
            style={{ display: isMobile ? 'block' : 'none' }}
            className="glide__arrows"
            data-glide-el="controls"
          >
            <button
              type="button"
              className="glide__arrow glide__arrow--left"
              data-glide-dir="<"
            >
              <img src={GrSeta} alt="anterior" />
            </button>
          </div>
          <div
            style={{ display: isMobile ? 'inline-flex' : 'none' }}
            className="glide__bullets"
            data-glide-el="controls[nav]"
          >
            {features.length > 0 &&
              features.map((feature, index) => {
                return (
                  <button
                    key={feature.description}
                    type="button"
                    className="glide__bullet"
                    data-glide-dir={`=${index}`}
                  />
                );
              })}
          </div>
          <div
            className="glide__arrows"
            data-glide-el="controls"
            style={{ display: isMobile ? 'block' : 'none' }}
          >
            <button
              type="button"
              className="glide__arrow glide__arrow--right"
              data-glide-dir=">"
            >
              <img src={GrSeta} alt="anterior" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselSolucoesComponent;
