import React from 'react';
import {
  PurposeDriven,
  QuemSomos,
  TrabalheConosco,
  OQueFazemos,
  Contato,
  Footer,
} from '../../components';
import { ContainerFluid } from './index.styled';

const HomePage = () => {
  return (
    <ContainerFluid>
      <PurposeDriven />
      <QuemSomos />
      <OQueFazemos />
      <TrabalheConosco />
      <Contato />
      <Footer />
    </ContainerFluid>
  );
};

export default HomePage;
