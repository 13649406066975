import React from 'react';
import { useResize } from '../../hooks';
import { Description, ButtonRounded, ButtonVagas } from '..';
import {
  ContainerFluid,
  Container,
  WrapperContent,
  ImageDestaque,
} from './index.style';

const TrabalheConoscoComponent = () => {
  const { isMobile } = useResize();
  return (
    <ContainerFluid id="trabalhe-conosco" className="fluid">
      <Container>
        <WrapperContent>
          <h2 className="subtitle pink">Trabalhe Conosco</h2>
          <div>
            <p
              className={
                !isMobile
                  ? 'destaque title-purple yellow-line smaller'
                  : 'destaque title-purple yellow-line'
              }
            >
              Nós buscamos profissionais dedicados, talentosos e apaixonados por
              criar soluções num ambiente dinâmico e colaborativo.
            </p>
          </div>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            É o seu caso?
            <br />
            Então faça parte do {isMobile && <br />} nosso time!
          </Description>
          <ButtonRounded
            style={{ marginTop: isMobile ? '40px' : '70px' }}
            onPress={e => {
              e.preventDefault();
              window.location = 'mailto:talentos@engenhos.net';
            }}
          >
            Envie seu currículo
          </ButtonRounded>
          <ButtonVagas style={{marginTop: '20px'}} />
          {!isMobile && <ImageDestaque />}
        </WrapperContent>
      </Container>
    </ContainerFluid>
  );
};

export default TrabalheConoscoComponent;
