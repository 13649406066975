import styled from 'styled-components';

export const ButtonClose = styled.button`
  width: 30px;
  height: 30px;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
`;
