import styled from 'styled-components';
import { GrQuemSomos } from '../../assets';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 0;
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    position: relative;
    min-height: 490px;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      width: 2500px;
      height: 845px;
      top: -38px;
      left: 788px;
      background: url(${GrQuemSomos});
      background-repeat: no-repeat;
      background-size: 2500px 845px;
      background-position: 0 0px;
      transform: scale(1);
      z-index: 0;
      pointer-events: none;
    }
  }
`;

export const ContainerFluid = styled.div`
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-top: 60px;
  z-index: 1;
  @media (min-width: 768px) {
    padding-top: 0px;
    min-height: 100vh;
  }
`;

export const WrapperContent = styled.div`
  @media (min-width: 768px) {
    max-width: 600px;

    .wrapper-title {
      max-width: 500px;
    }
  }
`;
