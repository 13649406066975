import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '..';
import {
  ContainerFull,
  DivContactInfos,
  Container,
  DivParagraph,
  Termos,
} from './index.styled';

const FooterComponent = () => {
  const navigateToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  };
  return (
    <ContainerFull>
      <Container>
        <Link to="/" onClick={navigateToTop}>
          <Logo className="bigger pointer" />
        </Link>
        <DivContactInfos>
          <DivParagraph>
            <a href="tel:+551142102304">+55 (11) 4210-2304</a>
          </DivParagraph>
          <DivParagraph className="separator">
            <a href="mailto:contato@engenhos.net">contato@engenhos.net</a>
          </DivParagraph>
        </DivContactInfos>
        <Termos>
          <Link to="/termos-e-condicoes-gerais-de-uso">Termos de uso</Link>
          {' e '}
          <Link to="/politica-de-privacidade">Política de Privacidade</Link>
        </Termos>
      </Container>
    </ContainerFull>
  );
};

export default FooterComponent;
