import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonContainer } from './index.styled';

const ButtonVagasComponent = ({ color, style }) => {

  return (
    <ButtonContainer>
      <Button style={style} color={color}>
        <Link to="./vagas" >
          Acessar nossas vagas
        </Link>
      </Button>
    </ButtonContainer>
  );
};

ButtonVagasComponent.propTypes = {
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf([null])]),
};

ButtonVagasComponent.defaultProps = {
  color: 'var(--color-purple-dark)',
  style: {},
};

export default ButtonVagasComponent;
