import React from 'react';
import { useResize } from '../../hooks';
import { Description } from '..';
import {
  ContainerFluid,
  Container,
  WrapperContent,
  WrapperContactInfo,
  WrapperInfo,
} from './index.style';

const ContatoComponent = () => {
  const { isMobile } = useResize();
  return (
    <ContainerFluid id="contato" className="fluid">
      <Container>
        <WrapperContent>
          <h2 className="subtitle pink">Contato</h2>
          <div>
            <p
              className={
                !isMobile
                  ? 'destaque title-purple yellow-line smaller'
                  : 'destaque title-purple yellow-line'
              }
            >
              Ficaremos felizes em desenvolver as soluções para os próximos
              desafios do seu negócio.
            </p>
          </div>
          <Description
            style={{
              marginTop: isMobile ? '40px' : '52px',
              marginBottom: isMobile ? '10px' : '0',
            }}
            color="#000000"
            type="description"
          >
            Vamos conversar?
          </Description>
          <WrapperContactInfo>
            <WrapperInfo>
              <h3>Novos negócios:</h3>
              {!isMobile && (
                <Description
                  style={{ marginTop: isMobile ? '10px' : '0px' }}
                  color="#000000"
                >
                  <a href="mailto:contato@engenhos.net">
                    contato@engenhos.net
                  </a>
                  <br />
                  <a href="tel:+551142102304">+55 (11) 4210-2304</a>
                </Description>
              )}
              {isMobile && (
                <Description
                  style={{ marginTop: isMobile ? '10px' : '0px' }}
                  color="#000000"
                >
                  <a href="mailto:contato@engenhos.net">
                    contato@engenhos.net
                  </a>
                  <br />
                  <a href="tel:+551142102304">+55 (11) 4210-2304</a>
                </Description>
              )}
              {!isMobile && (
                <Description
                  style={{
                    marginTop: isMobile ? '10px' : '30px',
                    textDecoration: 'underline',
                  }}
                  color="#000000"
                >
                  Av. Paulista, 171, 4º andar - São Paulo, SP, Brasil
                </Description>
              )}
            </WrapperInfo>
            <WrapperInfo>
              <h3>Suporte técnico:</h3>
              {!isMobile && (
                <Description
                  style={{ marginTop: isMobile ? '10px' : '0px' }}
                  color="#000000"
                >
                  <a href="mailto:suporte@engenhos.net">suporte@engenhos.net</a>
                  <br />
                  <a href="tel:+551142102304">+55 (11) 4210-2304</a>
                </Description>
              )}
              {isMobile && (
                <Description
                  style={{ marginTop: isMobile ? '10px' : '0px' }}
                  color="#000000"
                >
                  <a href="mailto:suporte@engenhos.net">suporte@engenhos.net</a>
                  <br /> <a href="tel:+551142102304">+55 (11) 4210-2304</a>
                </Description>
              )}
              {isMobile && (
                <Description
                  style={{
                    marginTop: isMobile ? '10px' : '30px',
                  }}
                  color="#000000"
                >
                  Av. Paulista, 171, 4º andar - São Paulo, SP, Brasil
                </Description>
              )}
            </WrapperInfo>
          </WrapperContactInfo>
        </WrapperContent>
      </Container>
    </ContainerFluid>
  );
};

export default ContatoComponent;
