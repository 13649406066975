import styled from 'styled-components';
import { motion } from 'framer-motion';
import { BackgroundPurpose } from '../../assets';

export const ContainerFluid = styled.div`
  width: 100%;
  background: var(--color-white);
  padding: 0 20px;

  &.bg-header {
    background-image: url(${BackgroundPurpose}),
      linear-gradient(245deg, #821c85, #c90263);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;

    @media (min-width: 768px) {
      height: 100vh;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 4rem;

  @media (min-width: 768px) {
    padding-bottom: 4.6rem;
  }
`;

export const Section = styled.section`
  width: 100%;
  color: var(--color-white);
`;

export const Title = styled(motion.h1)`
  color: inherit;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    font-size: 3.6rem;
  }
`;

export const Paragraph = styled(motion.p)`
  color: inherit;
  margin: 0 0 0.8rem 0;
  font-size: 0.9rem;
  line-height: 1.5rem;

  @media (min-width: 768px) {
    margin: 0;
  }

  span {
    font-family: 'padrao-bold', 'padrao';
    font-size: 0.9rem;
  }
`;
