import React from 'react';
import { useResize } from '../../hooks';
import { Description } from '..';
import { ContainerFluid, Container, WrapperContent } from './index.styled';

const TermosComponent = () => {
  const { isMobile } = useResize();

  const textMarginDesktop = '20px';
  return (
    <ContainerFluid id="politica" className="fluid">
      <Container>
        <WrapperContent>
          <div className="wrapper-title">
            <p className="destaque title-purple smaller">
              TERMOS E CONDIÇÕES GERAIS DE USO.
            </p>
          </div>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            Estes termos e condições (<b>“TERMOS”</b>) se aplicam à utilização
            do nosso website por você, o qual é mantido pela pessoa jurídica{' '}
            <b>ENGENHOS SOLUÇÕES EM TECNOLOGIA EIRELI </b>(
            <b>&quot;ENGENHOS&quot;</b>) , devidamente registrada sob o CNPJ nº
            30.032.481/0001-88, e-mail:{' '}
            <a href="mailto:contato@engenhos.net">contato@engenhos.net</a>, com
            sede a Av. Paulista, nº 171, 4º andar, Bela Vista, São Paulo/SP,
            CEP: 01.311-000.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            Estes <b>TERMOS</b> aplicam-se e obrigam todos aqueles que acessarem
            o nosso website, não podendo, estes, eximirem-se de quaisquer
            responsabilidades alegando o desconhecimento deste documento.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            1. DEFINIÇÕES:
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            &quot;Nós&quot;, &quot;Nosso&quot; ou &quot;Nossos&quot; refere-se a{' '}
            <b>ENGENHOS SOLUÇÕES EM TECNOLOGIA EIRELI (&quot;ENGENHOS&quot;)</b>
            , pessoa jurídica que mantém e é responsável pelo website;
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            {' '}
            &quot;Website&quot; é o website que pode ser acessado a partir do
            endereço “https:// engenhos.net”, incluindo todas as suas páginas e
            recursos;
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            {' '}
            &quot;Partes&quot; se refere tanto ao Usuário quanto a Nós,
            indistintamente;
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            &quot;Serviços&quot; se refere aos seguintes serviços prestados:
          </Description>
          <ul>
            <li>
              i. desenvolvimento de programas de computador sob encomenda;
            </li>
            <li>
              ii. desenvolvimento e licenciamento de programas de computador,
              customizáveis ou não; e
            </li>
            <li>iii. consultoria em tecnologia da informação.</li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            &quot;Usuário&quot; é a pessoa que navega ou que de qualquer outra
            forma utiliza Nosso website.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            2. OBJETO:
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            2.1. Estes termos regulam o relacionamento e a utilização do serviço
            deste website pelos seus respectivos usuários incluindo todos os
            recursos, funcionalidades e alterações que possam vir a ser feitas.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            2.2. O usuário manifesta, pela utilização deste website, o seu
            expresso, automático, pleno, sem reservas ou ressalvas consentimento
            com todas as condições destes Termos de Uso, bem como da Política de
            Privacidade e Dados. Havendo quaisquer discordâncias acerca destes,
            o usuário deverá abster-se de usufruir dos serviços aqui ofertados.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            3. O SERVIÇO ENGENHOS
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            3.1. O serviço <b>ENGENHOS</b> corresponde ao desenvolvimento de
            programas de computador sob encomenda, desenvolvimento e
            licenciamento de programas de computador, customizáveis ou não, e
            consultoria em tecnologia da informação
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            3.2. O serviço será descrito, apresentado e realizado com o maior
            grau de exatidão possível, acompanhado de informações e atualizações
            claras, precisas, ostensivas e em língua portuguesa; serão
            repassadas informações sobre características, qualidades,
            quantidades, composições, estágios, etapas, forma de
            desenvolvimento, prazos de entrega, óbices à prestação e quaisquer
            outras questões que se fizerem prudentes.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            3.3. Todos os pormenores relativos ao serviço a ser prestado serão
            devidamente discutidos, dirimidos e alinhados através das devidas
            negociações, reuniões, etc., e pontuados através do Contrato de
            Prestação de Serviços firmado entre as partes.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            3.4. Os valores correspondentes ao preço do serviço, taxas e demais
            quantias a serem envolvidas serão mensuradas caso a caso; a ENGENHOS
            reserva o seu direito de manter a política de preço que mais lhe
            convir e suprir seu funcionamento e operacionalização.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            3.5. Todos os custos serão combinados previamente, remanescendo
            apenas aqueles imprevistos e/ou provenientes de ajustes, acréscimos
            e mudanças no escopo inicialmente demandado.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            3.6. Nas situações em que se for trabalhar mediante contratação de
            horas avulsas ou em pacotes, será combinado o custo por hora e, ao
            término da prestação ou prazo acordado, apresentada demonstração
            correspondente ao tempo demandado.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            3.7. A ENGENHOS é titular dos direitos referentes aos conteúdos
            exibidos em sua plataforma e quaisquer violações e/ou tentativas
            serão puníveis nas formas da lei.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            4. RESPONSABILIDADE DO USUÁRIO
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            4.1. Para a navegação neste website, bem como para o consumo de
            quaisquer serviços ofertados, o Usuário compreende e reconhece que:
          </Description>
          <Description
            style={{
              marginTop: isMobile ? '10px' : { textMarginDesktop },
            }}
            fontNormal
            color="#000000"
          >
            4.1.1. Possui capacidade jurídica para celebrar esta relação
            contratual;
          </Description>
          <ul>
            <li>
              4.1.1. Possui capacidade jurídica para celebrar esta relação
              contratual;
            </li>
            <li>
              4.1.2. Se menor, deverá ser representado e/ou assistido por seus
              representantes legais;
            </li>
            <li>
              4.1.3. Toda informação repassada à ENGENHOS é verdadeira e
              autêntica, sendo o usuário o único responsável por estas;
            </li>
            <li>
              4.1.4. Autoriza a coleta e tratamento de dados pela ENGENHOS;
            </li>
            <li>4.1.5. Leu e compreendeu todas as disposições aqui postas;</li>
            <li>
              4.1.6. Utilizará o serviço com boa-fé e em conformidade com os
              preceitos destes Termos de Uso e da Política de Privacidade e
              Dados, bem como em observância à legislação;
            </li>
            <li>
              4.1.7. Não irá arquivar, reproduzir, distribuir, modificar,
              exibir, executar, publicar, licenciar ou criar trabalhos
              derivados, colocar à venda ou utilizar (exceto nas formas
              expressamente autorizadas por estes Termos de Uso) o conteúdo e as
              informações contidas neste website e/ou plataformas;
            </li>
            <li>
              4.1.8. Não tentará, através de nenhum meio, acessar conteúdos ou
              informações que não tenham sido lhe disponibilizado de forma
              intencional;
            </li>
            <li>
              4.1.9. Não tentará violar, tampouco testar a vulnerabilidade,
              deste website e dos mecanismos de segurança e/ou infraestrutura
              utilizados por Nós; e
            </li>
            <li>
              4.1.10. Não tentará realizar nenhuma engenharia reversa ou
              utilizar quaisquer softwares e métodos que possam contornar a
              segurança do serviço para manipular ou adulterar o conteúdo, bem
              como não utilizará métodos de data mining, coleta e/ou extração de
              dados.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            5. LIMITAÇÕES E EXCLUSÃO DE GARANTIAS OU RESPONSABILIDADES
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            5.1. Os Serviços são fornecidos “No estado em que se encontram”, sem
            garantias ou condições. Primordialmente, o serviço não está livre de
            interrupções e/ou erros, bem como não é possível evitar a sua
            falibilidade.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            5.2. A <b>ENGENHOS</b> não pode garantir que o conteúdo e acesso ao
            website e/ou plataforma seja contínuo e isento de
            indisponibilidades, falhas, imprecisões técnicas e erros em todos os
            aspectos.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            5.3. Ainda, a <b>ENGENHOS</b> não será responsável por nenhum dano
            ocorrido aos usuários decorrentes de falhas que estão fora do seu
            controle, tais como, mas não se limitando a:
          </Description>
          <ul>
            <li>
              5.3.1. Utilização de equipamentos que impeçam o bom funcionamento
              do Serviço ofertado;
            </li>
            <li>
              5.3.2. Falha na comunicação com a conexão de internet por conta do
              usuário;
            </li>
            <li>
              5.3.3. Problemas com o acesso aos links para diferentes websites
              que estejam disponíveis neste website; e
            </li>
            <li>5.3.4. Casos fortuitos ou de força maior.</li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            5.4. Sendo necessário, Nós poderemos:
          </Description>
          <ul>
            <li>
              5.4.1. Suspender, interromper ou limitar o acesso ao website por
              completo ou apenas a uma parte;
            </li>
            <li>
              5.4.2. Remover toda e qualquer informação do website conforme
              Nosso melhor critério; e
            </li>
            <li>
              5.4.3. Suspender ou desativar o website tanto para atualizações,
              quanto para o seu encerramento.
            </li>
          </ul>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            6. LINKS EXTERNOS
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            6.1. O Nosso website poderá conter links externos que redirecionem
            os Usuários a outras páginas da internet sobre as quais não
            possuímos quaisquer tipos de controle.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            6.2. A opção por realizar o acesso a estes supracitados links é
            única e exclusiva do Usuário e a ENGENHOS em nada se
            responsabilizará pelos conteúdos encontrados, tampouco pelos
            problemas que deles forem provenientes.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            7. PROPRIEDADE INTELECTUAL
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            7.1. A estrutura da plataforma, website e serviços, bem como
            códigos, programações, gráficos, imagens, textos, fotografias, sons,
            vídeos e as demais aplicações informáticas que o compõe são de
            inteira propriedade da <b>ENGENHOS</b> e protegidas pela legislação
            nacional referente à propriedade intelectual.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            7.2. Qualquer reprodução, representação, adaptação ou exploração
            parcial ou total dos conteúdos, marcas e serviços propostos pela
            plataforma, por qualquer meio que seja, sem autorização prévia,
            expressa e escrita pelos responsáveis legais da <b>ENGENHOS</b>, é
            estritamente vedada, podendo-se recorrer às medidas cíveis e
            criminais cabíveis.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            8. SUPORTE E COMUNICAÇÃO COM O USUÁRIO
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            8.1. O suporte ao usuário será realizado através do e-mail
            <a href="mailto:suporte@engenhos.net"> suporte@engenhos.net</a>, de
            segunda-feira a sexta-feira, das 09h (nove horas) às 18h (dezoito
            horas), em dias úteis, conforme calendário do munícipio de São
            Paulo/SP.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            9. ACEITE INEQUÍVOCO
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            9.1. A utilização deste website, plataforma e serviços após a
            leitura destes Termos de Uso e da Política de Privacidade e Dados, o
            que se considera tacitamente como realizado, concede o aceite
            inequívoco acerca dos dispostos nestes. Nenhum usuário poderá alegar
            desconhecimento das regras e funcionamento deste website e/ou
            plataforma.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            10. ESTIPULAÇÕES FINAIS
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            10.1. A presente versão destes Termos foi atualizada pela última vez
            em 10
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            10.2. A ENGENHOS reserva-se o direito de modificar, ao seu único
            critério, estes Termos de Uso e sua Política de Privacidade sem
            aviso prévio; assim, o usuário deverá acessar estes documentos a
            cada visita no website e verificar possíveis alterações. A
            continuidade de acesso ao website e utilização do serviço ensejará o
            aceite das eventuais atualizações.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
            fontNormal
            color="#000000"
          >
            10.3. Para a solução de controvérsias decorrentes deste instrumento,
            elege-se o foro de São Paulo/SP.
          </Description>
          <Description
            style={{
              marginTop: isMobile ? '10px' : { textMarginDesktop },
              textAlign: 'right',
            }}
            fontNormal
            color="#000000"
          >
            São Paulo/SP, 10 de março de 2021.
          </Description>
        </WrapperContent>
      </Container>
    </ContainerFluid>
  );
};

export default TermosComponent;
