import React from 'react';
import { Footer, Description } from '../../components';
import { useResize } from '../../hooks';
import { ContainerFluidMain, ContainerFluid, Container, WrapperContent } from './index.styled';

const Vaga57DevPlPage = () => {
  const { isMobile } = useResize();
  const textMarginDesktop = '20px';

  return (
    <ContainerFluidMain>
        <ContainerFluid id="vaga" className="fluid">
          <Container>
            <WrapperContent>
              <div className="wrapper-title">
                <p className="destaque title-purple smaller">
                  Oportunidade: Desenvolvedor(a) .NET Fullstack Pleno
                </p>
              </div>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Aqui na Engenhos nos empenhamos em construir um ambiente no qual possamos ser felizes trabalhando com o que gostamos, tecnologia, e desenvolver times com diversidade é o primeiro passo para este objetivo.
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Estamos buscando pessoas de qualquer parte do Brasil para atuar como Desenvolvedor(a) .NET Fullstack em Home Office Full-time. 
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Buscamos pessoas que não tenham medo de desafios e desejam conciliar o desenvolvimento profissional e pessoal, sempre antenados em novas tecnologias, dispostos a aprender e trazer as novidades tecnológicas e boas práticas da área para compartilhar com o time.
              </Description>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                COMPETÊNCIAS TÉCNICAS
              </Description>
              <ul>
                <li>
                C# e .NET Core 
                </li>
                <li>
                REST API  
                </li>
                <li>
                JavaScipt/TypeScript  
                </li>
                <li>
                HTML 5  
                </li>
                <li>
                CSS  
                </li>
                <li>
                Angular 5+  
                </li>
                <li>
                Banco de dados relacional 
                </li>
                <li>
                Entity Framework 
                </li>
                <li>
                Desenvolvimento de sistemas conteinerizados 
                </li>
              </ul>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                HABILIDADES PESSOAIS
              </Description>
              <ul>
                <li>
                Assumir com o time a responsabilidade pela entrega e a responsabilidade pelo desenvolvimento do próprio time (trabalho em equipe) 
                </li>
                <li>
                Iniciativa junto ao time  
                </li>
                <li>
                Comunicação clara   
                </li>
                <li>
                Compartilhar o conhecimento com os demais  
                </li>
                <li>
                Organização pessoal para trabalhar de casa  
                </li>
              </ul>
              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                HABILIDADES PESSOAIS
              </Description>
              <ul>
                <li>
                Desenvolvimento e manutenção de aplicações web; 
                </li>
                <li>
                Desenvolvimento Front-End e Back-End; 
                </li>
                <li>
                Atuar ativamente nas cerimônias do time; 
                </li>
                <li>
                Buscar novas ferramentas e técnicas para a melhoria técnica de nossos sistemas; 
                </li>
              </ul>

              <Description
                style={{ marginTop: isMobile ? '24px' : '30px' }}
                color="#000000"
                type="description"
              >
                PARA SE CANDIDATAR
              </Description>
              <Description
                style={{ marginTop: isMobile ? '10px' : { textMarginDesktop } }}
                fontNormal
                color="#000000"
              >
                Envie seu currículo para <a href="mailto:eliane.pimentel@engenhos.net">eliane.pimentel@engenhos.net</a>
              </Description>
            </WrapperContent>
          </Container>
        </ContainerFluid>
      <Footer />
    </ContainerFluidMain>
  );
};

export default Vaga57DevPlPage;
