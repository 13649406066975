import styled from 'styled-components';
import { MenuHamburguer } from '../../assets';

export const Container = styled.div`
  display: flex;
`;

export const Nav = styled.nav`
  display: flex;
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.li`
  text-transform: uppercase;
  margin: 0 15px;
  letter-spacing: 2px;
  font-size: 0.7rem;
  opacity: 1;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
    a {
      color: ${p => (p.color === 'white' ? '#FFF' : '#e3c135')};
    }
  }
  a {
    color: ${p => (p.color === 'white' ? '#FFF' : '#3c0548')};
    transition: all 0.3s ease;
  }
`;

export const Hamburguer = styled.button`
  width: 20px;
  height: 18px;
  mask: url(${MenuHamburguer}) no-repeat center;
  mask-size: contain;
  background: ${p => p.color};
  border: none;
  outline: none;
  margin-bottom: 4px;
`;
