import styled from 'styled-components';
import { IconArrow } from '../../assets';

export const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-bottom: 30px;
    width: 100%;
    justify-content: flex-end;
  }
`
export const Button = styled.div`
  position: relative;
  display: inline-flex;
  font-family: 'titulos';
  align-items: center;
  background: var(--color-white);
  color: ${p => p.color};
  border: solid 2px ${p => p.color};
  height: 44px;
  border-radius: 30px;
  font-size: 0.7rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;

  a {
    display: inline-flex;
    align-items: center;
    color: inherit;
    height: inherit;
    padding: 0 30px 0 56px;
  }

  a:hover {
    color: inherit;
  }

  &:hover {
    background: ${p => p.color};
    color: var(--color-white);

    &:after {
      background: var(--color-white);
    }
  }

  @media (min-width: 768px) {
    height: 58px;
    border-radius: 30px;
    font-size: 0.8rem;

    a {
      padding: 0 30px 0 62px;
    }
  }

  &:after {
    position: absolute;
    content: '';
    top: 43%;
    transform: translateY(-50%);
    left: 20px;
    width: 22px;
    height: 10px;
    transform: scaleX(-1);
    mask-image: url(${IconArrow});
    mask-repeat: no-repeat;
    mask-size: contain;
    background: ${p => p.color};
    transition: all 0.3s ease;
    pointer-events: none;

    @media (min-width: 768px) {
      left: 30px;
    }
  }
`;
