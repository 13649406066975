import React from 'react';
import { Link } from 'react-router-dom';
import { Footer, Description, ButtonRounded } from '../../components';
import { useResize } from '../../hooks';
import { ContainerFluidMain, ContainerFluid, Container, WrapperContent, ActiveLinks } from './index.styled';

const VagasPage = () => {
  const { isMobile } = useResize();

  const arVagas = [
    {
      link: './vagas/58-techlead',
      description: '#58 - Techlead'
    }
  ]

  const renderVagas = () => {

    const vagas = arVagas.map((vaga, index) => {
      return(
      <ActiveLinks key={`vagas-${index}`}>
          <Link to={vaga.link}>{vaga.description}</Link>
      </ActiveLinks>
      )
    })

    return vagas;
  }

  return (
    <ContainerFluidMain>
        <ContainerFluid className="fluid">
          <Container>
          <WrapperContent>
          <h2 className="subtitle pink">Trabalhe Conosco</h2>
          <div>
            <p
              className={
                !isMobile
                  ? 'destaque title-purple yellow-line smaller'
                  : 'destaque title-purple yellow-line'
              }
            >
              Nós buscamos profissionais dedicados, talentosos e apaixonados por
              criar soluções num ambiente dinâmico e colaborativo.
            </p>
          </div>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            É o seu caso?
            <br />
            Então faça parte do {isMobile && <br />} nosso time!
          </Description>
          <ButtonRounded
            style={{ marginTop: '10px',
                    marginBottom: '50px' }
                  }
            onPress={e => {
              e.preventDefault();
              window.location = 'mailto:talentos@engenhos.net';
            }}
          >
            Envie seu currículo
          </ButtonRounded>
        </WrapperContent>
        {arVagas.length > 0 &&
          <WrapperContent>
            <h2 className="subtitle pink">Lista de vagas disponíveis:</h2>
            {renderVagas()}
          </WrapperContent>
        }
        </Container>
        </ContainerFluid>
      <Footer />
    </ContainerFluidMain>
  );
};

export default VagasPage;
