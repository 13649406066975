/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  AnimatePresence,
  useViewportScroll,
  useTransform,
} from 'framer-motion';
import { withRouter, useLocation, Link } from 'react-router-dom';
import { useResize } from '../../hooks';
import { Logo, MenuHeader, MenuMobile } from '..';
import { Container } from '../../assets/globalStyle';
import { ContainerFull } from './index.styled';

const HeaderComponent = () => {
  const [openMenu, setMenuOpen] = useState(false);
  const [typeLogo, setTypeLogo] = useState('white');
  const { isMobile } = useResize();
  const { pathname } = useLocation();

  const navigateToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  };

  const [changeHeader, setChangeHeader] = useState(pathname.length === 1);
  const { scrollYProgress } = useViewportScroll();
  const logoType = useTransform(scrollYProgress, [0, 0.27], [0, 1.3]);
  const headerOpacity = useTransform(
    scrollYProgress,
    [0.15, 0.23],
    ['#FFFFFF00', '#FFFFFFFF']
  );
  useEffect(() => {
    logoType.onChange(v => {
      if (v >= 1) {
        setTypeLogo('color');
      } else if (v < 1) {
        setTypeLogo('white');
      }
    });
  }, []);

  useEffect(() => {
    setChangeHeader(pathname.length === 1);

    {
      /* Scroll to top withou smooth when it is not home */
    }
    if (pathname.length !== 1) {
      document.documentElement.style.setProperty('--scroll', 'auto');
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }, 10);
      setTimeout(() => {
        document.documentElement.style.setProperty('--scroll', 'smooth');
      }, 500);
    }
  }, [pathname]);

  useEffect(() => {
    if (!isMobile) setMenuOpen(false);
  }, [isMobile]);
  return (
    <ContainerFull
      color={changeHeader ? typeLogo : 'color'}
      style={{
        background: changeHeader ? headerOpacity : 'rgba(255, 255, 255, 1)',
      }}
    >
      <AnimatePresence>
        {openMenu && (
          <MenuMobile closeMenuMobile={setMenuOpen} color={typeLogo} />
        )}
      </AnimatePresence>
      <Container>
        <Link to="/" onClick={navigateToTop}>
          <Logo type={changeHeader ? typeLogo : 'color'} className="pointer" />
        </Link>
        <MenuHeader
          openMenuMobile={setMenuOpen}
          color={changeHeader ? typeLogo : 'color'}
        />
      </Container>
    </ContainerFull>
  );
};

export default withRouter(HeaderComponent);
