import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerFull = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
  height: 80px;
  z-index: 2;

  @media (min-width: 768px) {
    height: 116px;
  }

  border-bottom: solid 1px
    ${p => (p.color === 'white' ? 'transparent' : 'rgba(134,37,155, 0.4)')};

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
`;
