import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: 60px;

  @media (min-width: 768px) {
    height: 90px;
  }

  img {
    height: inherit;
  }
`;
