import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonContainer } from './index.styled';

const HeaderVagasComponent = ({ color, style }) => {

  return (
    <ButtonContainer>
      <Button style={style} color={color}>
        <Link to="../vagas" >
          Voltar para vagas
        </Link>
      </Button>
    </ButtonContainer>
  );
};

HeaderVagasComponent.propTypes = {
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf([null])]),
};

HeaderVagasComponent.defaultProps = {
  color: 'var(--color-purple-dark)',
  style: {},
};

export default HeaderVagasComponent;
