import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import menu from '../../mock-data/menu.json';
import { ButtonClose } from '..';
import { Background, Container, Menu, Nav, Button } from './index.styled';

const MenuMobileComponent = ({ closeMenuMobile }) => {
  const closeMobile = () => {
    closeMenuMobile(false);
  };
  return (
    <Background
      key="center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.2 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      transition={{ delay: 0 }}
    >
      <Container
        key="menu"
        initial={{ opacity: 0, x: '110vw' }}
        animate={{
          opacity: 1,
          x: 0,
          transition: { duration: 0.5, delay: 0.2 },
        }}
        exit={{ opacity: 0, x: '110vw', transition: { duration: 0.35 } }}
        transition={{ delay: 0 }}
      >
        <ButtonClose size="14px" handleClick={closeMobile} />
        <Nav>
          <Menu>
            {menu.map(button => {
              return (
                <Button key={button.title}>
                  <Link to={`../${button.anchor}`} onClick={closeMobile}>
                    {button.title}
                  </Link>
                </Button>
              );
            })}
          </Menu>
        </Nav>
      </Container>
    </Background>
  );
};

MenuMobileComponent.propTypes = {
  closeMenuMobile: PropTypes.func,
};

MenuMobileComponent.defaultProps = {
  closeMenuMobile: () => {},
};

export default MenuMobileComponent;
