import styled from 'styled-components';
import { IconArrow } from '../../assets';

export const Button = styled.button`
  position: relative;
  display: inline-flex;
  font-family: 'titulos';
  align-items: center;
  background: var(--color-white);
  color: ${p => p.color};
  border: solid 2px ${p => p.color};
  height: 44px;
  border-radius: 30px;
  padding: 0 56px 0 30px;
  font-size: 0.7rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${p => p.color};
    color: var(--color-white);

    &:after {
      background: var(--color-white);
    }
  }

  @media (min-width: 768px) {
    height: 58px;
    border-radius: 30px;
    padding: 0 62px 0 30px;
    font-size: 0.8rem;
  }

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    width: 22px;
    height: 10px;
    mask-image: url(${IconArrow});
    mask-repeat: no-repeat;
    mask-size: contain;
    background: ${p => p.color};
    transition: all 0.3s ease;
  }
`;
